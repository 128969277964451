import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private route: ActivatedRoute) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }

  }

  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  public isAuthenticated() {
    return !!localStorage.getItem('authToken');
  }
}
