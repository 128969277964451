<section class="middle-dashboard">
  <div class="right-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="main-inner-block">
            <div class="manager-detail-block">
              <!-- <h4 class="backToManager">Managers</h4> -->
              <div class="row pt-3 pb-3">
                <div class="col-lg-12">
                  <div class="manager-detail-top-row d-flex justify-content-between">
                    <ul class="managerName-days-list d-flex justify-content-start">
                      <li>Manager Name</li>
                      <li>
                        <i aria-hidden="true" class="fa fa-circle yellow"></i> Inactive from 4 days
                      </li>
                    </ul>
                    <ul class="edit-del-list d-flex justify-content-start">
                      <li data-dismiss="modal" data-toggle="modal" data-target="#deleteManagerModal">
                        <i class="fa fa-trash" aria-hidden="true"></i> Archive
                      </li>
                      <li data-dismiss="modal" data-toggle="modal" data-target="#editManagerModal">
                        <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row pt-3 pb-3 manager-info">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Email</label>
                    <span>karthik.govindarajan@lnttechservices.com</span>
                  </div>
                  <div class="form-group">
                    <label>Designation</label>
                    <span>Project Managment</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-lg-4">
                        <label>Country Code</label>
                        <span>+91</span>
                      </div>
                      <div class="col-lg-8">
                        <label>Phone Number</label>
                        <span>+91 9865878956</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Department</label>
                    <span>Project Managment</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Role</label>
                    <span>Super Admin</span>
                  </div>
                  <div class="form-group">
                    <label>Role </label>
                    <span>Bangalore</span>
                  </div>
                </div>
              </div>
              <div class="row pt-3 pb-3">
                <div class="col-lg-12 project-list-main">
                  <h4>Project List</h4>
                  <ul class="project-list">
                    <li>
                      <p>Project Name</p>
                      <p>Project Started date </p>
                      <p>Status</p>
                      <p>Confidence Parameter</p>
                      <p>Overall Progress</p>
                    </li>
                    <li>
                      <p>BC- Project 1</p>
                      <p>Feb 06 2018</p>
                      <p>
                        <button class="archive-list-btn" type="button">Ongoing</button>
                        In Design
                      </p>
                      <p>78%</p>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 75%;" aria-valuenow="75"
                             aria-valuemin="0" aria-valuemax="100">75%
                        </div>
                      </div>
                    </li>
                    <li>
                      <p>BC- Project 2</p>
                      <p>Feb 06 2018</p>
                      <p>
                        <button class="archive-list-btn archive-completed-btn" type="button">Completed</button>
                      </p>
                      <p>100%</p>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="100"
                             aria-valuemin="0" aria-valuemax="100">100%
                        </div>
                      </div>
                    </li>
                    <li>
                      <p>BC- Project 3</p>
                      <p>Feb 06 2018</p>
                      <p>
                        <button class="archive-list-btn archive-blocked-btn" type="button">Blocked</button>
                      </p>
                      <p>0%</p>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85"
                             aria-valuemin="0" aria-valuemax="100">85%
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--Delete Manager-->
<div class="client-modal modal fade" id="deleteManagerModal" tabindex="-1" role="dialog"
     aria-labelledby="modalCenterTitle"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal">
      <div class="modal-body">
        <h2>Archive Manager</h2>
        <p>Please assign the projects before archiving the manager.</p>
        <ul class="archive-list">
          <li>
            <p>Project Name</p>
            <p>Status</p>
          </li>
          <li>
            <p>BC- Project V1.2</p>
            <button class="archive-list-btn" type="button">Ongoing</button>
          </li>
          <li>
            <p>BC- Project V1.3</p>
            <button class="archive-list-btn archive-completed-btn" type="button">Completed</button>
          </li>
          <li>
            <p>BC- Project V1.4</p>
            <button class="archive-list-btn archive-blocked-btn" type="button">Blocked</button>
          </li>
        </ul>
        <div class="form-group d-flex justify-content-end btn-block">
          <input class="btn cancel-btn" type="reset" name="" value="Cancel">
          <input class="btn add-btn" type="submit" name="" value="Archive">
        </div>
      </div>
    </div>
  </div>
</div>

<!--Edit Manager-->
<div class="client-modal modal fade" id="editManagerModal" tabindex="-1" role="dialog"
     aria-labelledby="modalCenterTitle"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal">
      <div class="modal-body">
        <h2>Edit Manager</h2>
        <form>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-6">
                <label>First Name
                  <span>*</span>
                </label>
                <input class="form-control mb-sm-3 mb-lg-auto" type="text" placeholder="First Name">
              </div>
              <div class="col-lg-6">
                <label>Last Name
                  <span>*</span>
                </label>
                <input class="form-control" type="text" placeholder="Last Name">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Email
              <span>*</span>
            </label>
            <input class="form-control" type="email" placeholder="clientemail@gmail.com">
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-4">
                <label>Country Code</label>
                <input class="form-control mb-sm-3 mb-lg-auto" type="text" placeholder="+91">
              </div>
              <div class="col-lg-8">
                <label>Phone Number</label>
                <input class="form-control" type="text" placeholder="9865878956">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Role
              <span>*</span>
            </label>
            <div class="select-box">
              <select class="form-control">
                <option>Super Admin</option>
                <option>Admin</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label>Designation</label>
            <input class="form-control" type="text" placeholder="Project Manager">
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-6">
                <label>Department</label>
                <div class="select-box half mb-sm-3 mb-lg-auto">
                  <select class="form-control">
                    <option>Select Department</option>
                    <option>Select Department</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <label>Office</label>
                <div class="select-box half">
                  <select class="form-control">
                    <option>Bangaluru</option>
                    <option>Mumbai</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group d-flex justify-content-end btn-block">
            <input class="btn cancel-btn" type="reset" name="" value="Cancel">
            <input class="btn add-btn" type="submit" name="" value="Save Changes">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
