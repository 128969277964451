<app-header *ngIf='!commonService.newHeadertrue'></app-header>
<app-revamp-header *ngIf = 'commonService.newHeadertrue'></app-revamp-header>
<app-main-leftpanel *ngIf="dataService.user"></app-main-leftpanel>

<!-- <div class="pm-loader" *ngIf="loaderService.showPageloader">
    <div class="loader">
        <div class="loader-rounded"></div>
        <div class="loader-content"></div>
    </div>
</div> -->

<div class="initialLoader" *ngIf="isLoader">
    <div class="homeLoader"><img src="assets/images/builder_black.png"/></div>
    <div class="loadingOverlay"></div>
</div>


<div class="initialLoader toasterwrap" *ngIf='toastMessageService.showToastMsg'>
    <div class="loadingOverlay"></div>
    <div class="project-success-msg" >
        <img src="assets/images/new-tick-revamp.svg" alt="">
        <h3>
            {{ toastMessageService.toastMessage }}
        </h3>
        <a class="ml-auto" (click)='closeSaveTost()'>
            <i class="fa fa-times" aria-hidden="true"></i>
        </a>
    </div>
</div>

<!--Error screens start-->
<!-- <div class="error-screen" *ngIf="showErrorScreen"> -->

    <!--Error screens 401-->
    <!-- <div class="error_401" *ngIf="showErrorScreen_401">
        <img src="assets/images/error_401.png" alt="">
        <h4>Error Code: 401</h4>
        <h1>Not Authorized...</h1>
        <p>Access is allowed only for authorized users.</p>
        <button type="button">Back to Home Page</button>
    </div> -->

    <!--Error screens 404-->
    <!-- <div class="error_404" *ngIf="showErrorScreen_404">
        <img src="assets/images/error_404.png" alt="">
        <h4>Error Code: 404</h4>
        <h1>Page not found...</h1>
        <p>We are unable to find the page you are looking for</p>
        <button type="button">Back to Home Page</button>
    </div> -->

    <!--Error screens 500-->
    <!-- <div class="error_500" *ngIf="showErrorScreen_500">
        <img src="assets/images/error_500.png" alt="">
        <h4>Error Code: 500</h4>
        <h1>System is Under Maintenence</h1>
        <p>We are preparing to serve you better.</p>
        <button type="button">Back to Home Page</button>
    </div>
    
</div> -->
<!--Error screens end-->

<router-outlet></router-outlet>

<app-footer *ngIf='!commonService.newHeadertrue'></app-footer>