<section class="middle-dashboard ongoing-max-card-dashboard">
  <ul class="ongoing-dashboard-nav">
    <li>
      <a href="javascript: void(0)">Project Dashboard</a>
    </li>
    <li>
      <a [routerLink]="['/ongoing-max-card']" href="javascript: void(0)">Basic Information</a>
    </li>
    <li>
      <a href="javascript: void(0)">Check List</a>
    </li>
    <li>
      <a href="javascript: void(0)">Invoices</a>
    </li>
    <li>
      <a [routerLink]="['/todos-queries']" href="javascript: void(0)">ToDos and Queries</a>
    </li>
    <li>
      <a href="javascript: void(0)">Notifications</a>
    </li>
    <li>
      <a href="javascript: void(0)">E Tracker Feed</a>
    </li>
    <li class="active">
      <a href="javascript: void(0)">Code Repository</a>
    </li>
    <li>
      <a href="javascript: void(0)">Documents</a>
    </li>
    <li>
      <a href="javascript: void(0)">Ideaboard</a>
    </li>
    <li>
      <a href="javascript: void(0)">Meetings</a>
    </li>
    <li>
      <a href="javascript: void(0)">Builds</a>
    </li>
    <li>
      <a href="javascript: void(0)">Change Request</a>
    </li>
  </ul>
  <div class="right-content task-main">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="main-inner-block">
            <div class="projects-block">
              <ul class="project-breadcrumb">
                <!-- <li>
                  <a href="javascript: void(0)">Ongoing Projects</a>
                </li>
                <li>></li>
                <li>Projects 1</li> -->
              </ul>
              <div class="row pt-1">
                <div class="col-lg-12">
                  <div class="projects-tab ongoing-tab code-repo">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="codeRepo-tab" data-toggle="tab" href="#codeRepo" role="tab"
                           aria-selected="true">
                          Code Repository
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content code-repo-block" id="myTabContent">
                      <div class="tab-pane fade show active" id="codeRepo" role="tabpanel"
                           aria-labelledby="codeRepo-tab">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="project-card codeRepo-card">
                              <h4 class="block-title">iOS</h4>
                              <div class="codeRepoCardRow d-flex justify-content-between align-items-center">
                                <span class="codeRepoIcon d-flex justify-content-center align-items-center">
                                  <img src="assets/images/ios.png" alt="">
                                </span>
                                <ul class="codeRepoInfoList">
                                  <li>Last Updated On :
                                    <strong>09-05-2018</strong>
                                  </li>
                                  <li>Lines Committed :
                                    <strong>5626 Lines</strong>
                                  </li>
                                </ul>
                              </div>
                              <div class="codeRepoCardRow codeRepoGitUrl">
                                <p>GitLab URL :
                                  <br>
                                  <strong>https://www.google.com/search?q=url&ie=utf-8&oe=utf-8&client=firefox-b-ab</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="project-card codeRepo-card">
                              <h4 class="block-title">Android</h4>
                              <div class="codeRepoCardRow d-flex justify-content-between align-items-center">
                                <span class="codeRepoIcon d-flex justify-content-center align-items-center">
                                  <img src="assets/images/android.png" alt="">
                                </span>
                                <ul class="codeRepoInfoList">
                                  <li>Last Updated On :
                                    <strong>09-05-2018</strong>
                                  </li>
                                  <li>Lines Committed :
                                    <strong>5626 Lines</strong>
                                  </li>
                                </ul>
                              </div>
                              <div class="codeRepoCardRow codeRepoGitUrl">
                                <p>GitLab URL :
                                  <br>
                                  <strong>https://www.google.com/search?q=url&ie=utf-8&oe=utf-8&client=firefox-b-ab</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="project-card codeRepo-card">
                              <h4 class="block-title">Back End</h4>
                              <div class="codeRepoCardRow d-flex justify-content-between align-items-center">
                                <span class="codeRepoIcon d-flex justify-content-center align-items-center">
                                  <img src="assets/images/backend.png" alt="">
                                </span>
                                <ul class="codeRepoInfoList">
                                  <li>Last Updated On :
                                    <strong>09-05-2018</strong>
                                  </li>
                                  <li>Lines Committed :
                                    <strong>5626 Lines</strong>
                                  </li>
                                </ul>
                              </div>
                              <div class="codeRepoCardRow codeRepoGitUrl">
                                <p>GitLab URL :
                                  <br>
                                  <strong>https://www.google.com/search?q=url&ie=utf-8&oe=utf-8&client=firefox-b-ab</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="project-card codeRepo-card">
                              <h4 class="block-title">Web</h4>
                              <div class="codeRepoCardRow d-flex justify-content-between align-items-center">
                                <span class="codeRepoIcon d-flex justify-content-center align-items-center">
                                  <img src="assets/images/web.png" alt="">
                                </span>
                                <ul class="codeRepoInfoList">
                                  <li>Last Updated On :
                                    <strong>09-05-2018</strong>
                                  </li>
                                  <li>Lines Committed :
                                    <strong>5626 Lines</strong>
                                  </li>
                                </ul>
                              </div>
                              <div class="codeRepoCardRow codeRepoGitUrl">
                                <p>GitLab URL :
                                  <br>
                                  <strong>https://www.google.com/search?q=url&ie=utf-8&oe=utf-8&client=firefox-b-ab</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="project-card codeRepo-card">
                              <h4 class="block-title">Watch OS</h4>
                              <div class="codeRepoCardRow d-flex justify-content-between align-items-center">
                                <span class="codeRepoIcon d-flex justify-content-center align-items-center">
                                  <img src="assets/images/watch-os.png" alt="">
                                </span>
                                <ul class="codeRepoInfoList">
                                  <li>Last Updated On :
                                    <strong>09-05-2018</strong>
                                  </li>
                                  <li>Lines Committed :
                                    <strong>5626 Lines</strong>
                                  </li>
                                </ul>
                              </div>
                              <div class="codeRepoCardRow codeRepoGitUrl">
                                <p>GitLab URL :
                                  <br>
                                  <strong>https://www.google.com/search?q=url&ie=utf-8&oe=utf-8&client=firefox-b-ab</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="project-card codeRepo-card">
                              <h4 class="block-title">Windows</h4>
                              <div class="codeRepoCardRow d-flex justify-content-between align-items-center">
                                <span class="codeRepoIcon d-flex justify-content-center align-items-center">
                                  <img src="assets/images/windows.png" alt="">
                                </span>
                                <ul class="codeRepoInfoList">
                                  <li>Last Updated On :
                                    <strong>09-05-2018</strong>
                                  </li>
                                  <li>Lines Committed :
                                    <strong>5626 Lines</strong>
                                  </li>
                                </ul>
                              </div>
                              <div class="codeRepoCardRow codeRepoGitUrl">
                                <p>GitLab URL :
                                  <br>
                                  <strong>https://www.google.com/search?q=url&ie=utf-8&oe=utf-8&client=firefox-b-ab</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="activity-feed">
                              <div class="activity-feed-header">
                                <h4>Activity Feed</h4>
                                <select class="feed-dropdown">
                                  <option>May 16 - 23</option>
                                  <option>May 24 - 31</option>
                                </select>
                              </div>
                              <div class="code-repo-graph">
                                <img src="assets/images/code-repo-graph.png" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="code-repo-timeline">
                        <h3>Time Line</h3>
                        <div class="timeline-block">
                          <ul class="timeline-list">
                            <li class="date-placed">
                              <span>03, May</span>
                            </li>
                            <li class="ios">iOS Build (390 Lines Commited)
                              <span class="description">Lorem ipsum dolor sit amet, consectetuer elit, sed diam nonummy nibh </span>
                              <span class="time">12 Minuts ago</span>
                            </li>
                            <li class="android">
                              Android Build (1100 Lines Commited)
                              <span class="description">Lorem ipsum dolor sit amet, consectetuer elit, sed diam nonummy nibh </span>
                              <span class="time">12 Minuts ago</span>
                            </li>
                            <li class="date-placed">
                              <span>09, May</span>
                            </li>
                            <li class="ios">iOS Build (390 Lines Commited)
                              <span class="description">Lorem ipsum dolor sit amet, consectetuer elit, sed diam nonummy nibh </span>
                              <span class="time">12 Minuts ago</span>
                            </li>
                            <li class="android">
                              Android Build (1100 Lines Commited)
                              <span class="description">Lorem ipsum dolor sit amet, consectetuer elit, sed diam nonummy nibh </span>
                              <span class="time">12 Minuts ago</span>
                            </li>
                            <li class="date-placed">
                              <span>09, May</span>
                            </li>
                            <li class="ios">iOS Build (390 Lines Commited)
                              <span class="description">Lorem ipsum dolor sit amet, consectetuer elit, sed diam nonummy nibh </span>
                              <span class="time">12 Minuts ago</span>
                            </li>
                            <li class="android">
                              Android Build (1100 Lines Commited)
                              <span class="description">Lorem ipsum dolor sit amet, consectetuer elit, sed diam nonummy nibh </span>
                              <span class="time">12 Minuts ago</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
