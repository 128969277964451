<section class="middle-dashboard ongoing-max-card-dashboard">
  <ul class="ongoing-dashboard-nav">
    <li>
      <a href="javascript: void(0)">Project Dashboard</a>
    </li>
    <li>
      <a [routerLink]="['/ongoing-max-card']" href="javascript: void(0)">Basic Information</a>
    </li>
    <li>
      <a href="javascript: void(0)">Check List</a>
    </li>
    <li>
      <a href="javascript: void(0)">Invoices</a>
    </li>
    <li>
      <a [routerLink]="['/todos-queries']" href="javascript: void(0)">ToDos and Queries</a>
    </li>
    <li>
      <a href="javascript: void(0)">Notifications</a>
    </li>
    <li>
      <a href="javascript: void(0)">E Tracker Feed</a>
    </li>
    <li>
      <a href="javascript: void(0)">Code Repository</a>
    </li>
    <li>
      <a href="javascript: void(0)">Documents</a>
    </li>
    <li>
      <a href="javascript: void(0)">Ideaboard</a>
    </li>
    <li>
      <a href="javascript: void(0)">Meetings</a>
    </li>
    <li class="active">
      <a href="javascript: void(0)">Builds and Release Notes</a>
    </li>
    <li>
      <a href="javascript: void(0)">Change Request</a>
    </li>
  </ul>
  <div class="right-content task-main">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="main-inner-block">
            <div class="projects-block">
              <ul class="project-breadcrumb">
                <!-- <li>
                  <a href="javascript: void(0)">Ongoing Projects</a>
                </li>
                <li>></li>
                <li>Projects 1</li> -->
              </ul>
              <button data-dismiss="modal" data-toggle="modal" data-target="#createBuildPopup"
                      class="add-task-top-btn btn" type="button">
                <i class="fa fa-plus-circle" aria-hidden="true"></i> Create Build
              </button>
              <div class="row pt-1">
                <div class="col-lg-12">
                  <div class="projects-tab ongoing-tab build-tab">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="shipped-tab" data-toggle="tab" href="#shipped" role="tab"
                           aria-selected="true">
                          Shipped
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="draft-tab" data-toggle="tab" href="#draft" role="tab"
                           aria-selected="true">
                          Draft
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content build-release-block" id="myTabContent">
                      <div class="tab-pane fade show active" id="shipped" role="tabpanel" aria-labelledby="shipped-tab">
                        <div class="no-shipped-block hide">
                          <div class="no-shipped-con">
                            <img src="assets/images/no-shipped.png" alt="">
                            <p>You don't have any shipped build.</p>
                          </div>
                        </div>
                        <div class="todosQueries shippedDraftCon">
                          <div class="todos-left">
                            <div class="todos-header">
                              <div class="projects-search-filter">
                                <form class="projects-search">
                                  <input type="submit" name="" value="">
                                  <input type="text" name="" value="" placeholder="Search">
                                </form>
                                <ul class="sort-filter-list">
                                  <li>
                                    <a href="javascript: void(0)">
                                      <img src="assets/images/sort.png" alt=""> Sort by
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="todos-inner">
                              <div class="no-results-found hide">
                                <img src="assets/images/search-icon-light.png" alt="">
                                <p>No Results Found.</p>
                              </div>
                              <ul class="tasks-queries-list">
                                <li class="active">
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="todos-right">
                            <div class="todos-header">
                              <div class="todos-right-header-inner">
                                <div class="assignedByName mr-auto">
                                  Name 01
                                </div>
                              </div>
                            </div>
                            <div class="todos-inner">
                              <div class="task-detail">
                                <div class="task-detail-row mt-3">
                                  <p>Release Name :
                                    <span>Name</span>
                                  </p>
                                  <p>Release Date and Time :
                                    <span>11 May 2018, 10:20 AM</span>
                                  </p>
                                  <p>Assigned to :
                                    <span>Priyanka Kochhar</span>
                                  </p>
                                </div>
                                <div class="task-detail-row">
                                  <label>Release Notes :</label>
                                  <textarea class="form-control" placeholder="Notes" rows="3"></textarea>
                                </div>
                                <div class="task-detail-row d-flex justify-content-start">
                                  <label>Attached File :</label>
                                  <div class="attach-file-info">
                                    <i class="fa fa-file-text" aria-hidden="true"></i>
                                    <span id="doc-name">Doc1.png</span>
                                    <i class="fa fa-download red" aria-hidden="true"></i>
                                    <i class="fa fa-times red" aria-hidden="true"></i>
                                  </div>
                                </div>
                                <div class="task-detail-row">
                                  <label class="inc-border">Live Link</label>
                                  <div class="trans-box">
                                    <p>link</p>
                                  </div>
                                </div>
                                <div class="task-detail-row">
                                  <label class="inc-border">Build Genrated By</label>
                                  <div class="trans-box">
                                    <p>Karthik </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade show" id="draft" role="tabpanel" aria-labelledby="draft-tab">
                        <div class="todosQueries shippedDraftCon">
                          <div class="todos-left">
                            <div class="todos-header">
                              <div class="projects-search-filter">
                                <form class="projects-search">
                                  <input type="submit" name="" value="">
                                  <input type="text" name="" value="" placeholder="Search">
                                </form>
                                <ul class="sort-filter-list">
                                  <li>
                                    <a href="javascript: void(0)">
                                      <img src="assets/images/sort.png" alt=""> Sort by
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="todos-inner">
                              <div class="no-results-found hide">
                                <img src="assets/images/search-icon-light.png" alt="">
                                <p>No Results Found.</p>
                              </div>
                              <ul class="tasks-queries-list">
                                <li class="active">
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                                <li>
                                  <div class="task-name-assigned">
                                    <p>
                                      <span>Name 01</span>
                                      <br>
                                      <span>
                                        <strong>Version:</strong> 0.01</span>
                                    </p>
                                  </div>
                                  <div class="created-due-date ml-auto">
                                    <p>
                                      <span>11 May 2018 </span>
                                      <br>
                                      <span>10:20 AM</span>
                                    </p>
                                  </div>
                                  <p>It is a long established fact that a reader will be distracted by the readable
                                    content
                                    of a page when looking at its layout. </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="todos-right">
                            <div class="todos-header">
                              <div class="todos-right-header-inner">
                                <div class="assignedByName mr-auto">
                                  Name 01
                                </div>
                                <div class="del-draft">
                                  <i class="fa fa-trash" aria-hidden="true"></i> Delete
                                </div>
                                <div class="timeline">
                                  <div class="attach-file">
                                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                                    <input type="file" id="uploadImage"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="todos-inner">
                              <div class="task-detail">
                                <div class="task-detail-row mt-3">
                                  <p>Release Name :
                                    <span>Name</span>
                                  </p>
                                  <p>Release Date and Time :
                                    <span>11 May 2018, 10:20 AM</span>
                                  </p>
                                  <p>Assigned to :
                                    <span>Priyanka Kochhar</span>
                                  </p>
                                </div>
                                <div class="task-detail-row">
                                  <label>Release Notes :</label>
                                  <textarea class="form-control" placeholder="Notes" rows="3"></textarea>
                                </div>
                                <div class="task-detail-row d-flex justify-content-start">
                                  <label>Attached File :</label>
                                  <div class="attach-file-info">
                                    <i class="fa fa-file-text" aria-hidden="true"></i>
                                    <span id="doc-name">Doc1.png</span>
                                    <i class="fa fa-download red" aria-hidden="true"></i>
                                    <i class="fa fa-times red" aria-hidden="true"></i>
                                  </div>
                                </div>
                                <div class="task-detail-row">
                                  <label class="inc-border">Live Link</label>
                                  <div class="trans-box">
                                    <p>link</p>
                                  </div>
                                </div>
                                <div class="task-detail-row">
                                  <label class="inc-border">Build Genrated By</label>
                                  <div class="trans-box">
                                    <p>Karthik </p>
                                  </div>
                                </div>
                                <div class="task-detail-row">
                                  <div class="d-flex justify-content-end btn-block">
                                    <input data-dismiss="modal" class="btn cancel-btn" type="button" name=""
                                           value="Save to Draft">
                                    <input class="btn add-btn" type="button" name="" value="Ship">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--Create Build Popup-->

<div class="client-modal modal fade" id="createBuildPopup" tabindex="-1" role="dialog"
     aria-labelledby="modalCenterTitle"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal">
      <div class="modal-body">
        <h2>Build Create Form</h2>
        <form>
          <div class="form-group">
            <label>Release Name
              <span>*</span>
            </label>
            <input class="form-control" type="text" name="" value="">
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label>Date
                  <span>*</span>
                </label>
                <div class="date-placed">
                  <input class="form-control" type="text" name="" value="" placeholder="14-05-2018">
                  <span class="date-icon">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <label>Time
                  <span>*</span>
                </label>
                <input class="form-control" type="text" name="" value="" placeholder="06:50 AM">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Release Notes</label>
            <textarea class="form-control" placeholder="Details" rows="3"></textarea>
          </div>
          <div class="form-group">
            <label>Attach File</label>
            <div class="attach-file">
              <i class="fa fa-paperclip" aria-hidden="true"></i>
              <span>Add Attachment</span>
              <input type="file" id="uploadImage"/>
            </div>
          </div>
          <div class="form-group">
            <label>Live Link</label>
            <input class="form-control" type="text" name="" value="">
          </div>
          <div class="form-group">
            <label>Assign to</label>
            <div class="select-box">
              <select class="form-control">
                <option>Client</option>
                <option>PM Lead</option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex justify-content-end btn-block">
            <input data-dismiss="modal" class="btn cancel-btn" type="button" name="" value="Save to Draft">
            <input class="btn add-btn" type="button" name="" value="Ship">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
