<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-5">
        <p>copyright &copy; Engineer.ai 2020</p>
      </div>
      <div class="col-lg-7">
        <ul class="footer-links">
          <!-- <li><a target="_blank" href="{{dataService.partnerData?.domain}}"><img src="assets/images/logo-engineerai.png" alt=""> Engineer.ai</a></li> -->
          <!-- <li><a target="_blank" href="https://www.engineer.ai"><img height="28px" src="assets/images/engineerai_new_logo.png" alt=""> Engineer.ai</a></li> -->
          <li><a target="_blank" href="{{dataService.partnerData?.builder_domain}}"><img src="assets/images/revamp-builderai-logo.svg" alt=""> </a></li>
          <li *ngIf = "dataService.partnerData?.code === 'e-ai' || dataService.partnerData?.code === 'liquidstudio'">
            <a target="_blank" href="{{dataService.partnerData?.tracker_frontend_url}}"><img src="assets/images/revamp-buildertracker-logo.svg" alt=""> </a></li>
          <li *ngIf = "dataService.partnerData?.code === 'e-ai' || dataService.partnerData?.code === 'liquidstudio'">
            <a target="_blank" href="{{dataService.partnerData?.vendor_frontend_domain}}"><img src="assets/images/revamp-builderhive-logo.svg" alt=""> </a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
