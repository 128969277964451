<!--Error screens 401-->
<div class="error-screen">
<div class="error_401" >
  <img src="assets/images/error_401.png" alt="">
  <!-- <h4>Error Code: 401</h4>
  <h1>Not Authorized...</h1> -->
  <p>You have been logged out of Builder360.</p>
  <button type="button" (click)='homePage()'>Back to Login Page</button>
</div>
</div>
