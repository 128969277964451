import {Injectable} from "@angular/core";

@Injectable()
export class ProjectStatusService {
  
  public projectTabStatus;

  constructor() {
  }
  
}
