<!--<header class="main-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4">
        <div class="logo">
          <a (click)="goToProjects()">
            <img [src]="dataService.partnerData?.logo? dataService.partnerData.logo : 'assets/images/builder_360_logo_new.png'" alt="">
          </a>
        </div>
      </div>
      <div class="col-lg-8">
        <div *ngIf="checkUserLogged()" class="head-right profile-hide profile-show">
          <div class="notifications">
            <i class="fa fa-bell-o" aria-hidden="true"></i>
            <span class="notifications-count">02</span>
          </div>
          <div class="manager_availability_status"> 
            <div class="status_label">
              <ul class="list-inline">
                <li class="badge-label">Status:</li>
                <li class="badge-stl" (click)='statusShow(statusShowHide, $event)'>
                  <div class="item-status-manager" [ngClass]='{"available-status" : commonService.currentStatus === "Available", "not-available-status" :  commonService.currentStatus === "Not Available", "do-not-disturb-status" :  commonService.currentStatus === "Do Not Disturb" }'>{{commonService.currentStatus}}</div>
                  <em class="zmdi zmdi-chevron-down cstm-down"></em>                                  
                </li>
              </ul>
              <div class="manager-status-dropdown" [ngClass]='{"available-status" : commonService.currentStatus === "Available", "not-available-status" :  commonService.currentStatus === "Not Available", "do-not-disturb-status" :  commonService.currentStatus === "Do Not Disturb" }'> 
                <div class="dropdown">  
                  <div class="dropdown-menu" [ngClass]='{"show" : statusShowHide, "hide" : !statusShowHide}'> 
                    <div class="dropdownMenuInner">
                      <div class="triangle"></div>
                      <div class="item-status-manager" [ngClass]='{"available-status" : status === "Available", "not-available-status" :  status === "Not Available", "do-not-disturb-status" :  status === "Do Not Disturb" }' *ngFor='let status of statusArray;' (click)='statusUpdate(status)'>{{status}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="member-profile">
            <div class="profileBlock" (click)= 'toggleHeaderDropDown($event)'>
            <div class="initial-circle">
              <span>{{ (userData?.first_name?.charAt(0) + userData?.last_name?.charAt(0)) | uppercase }}</span>
            </div>
            <button class="btn btn-secondary dropdown-toggle profile-arr" type="button" id="dropdownMenuButton"                     
              aria-haspopup="true" aria-expanded="false"></button>
            </div>  
            <div class="dropdown">
              <div class="dropdown-menu" [ngClass]='{"show": isShowHeaderDropDown}' >
                <div class="profile-detail">
                  <div class="profile-detail-img">
                    <img [src]="userData.profile_pic.url ? (commonService.updatedUserImage ? commonService.updatedUserImage : userData.profile_pic.url ) : 'assets/images/default-profile.png'"
                         alt="">
                  </div>
                  <p>{{ userData?.first_name +' '+ userData.last_name }}<br> <span>{{ (userData?.role === 'pm') ? 'PM' : 'PM Lead' }}</span></p>
                </div> 
                <div class="dropdown-divider"></div>
                <div class="dropdownMenuInner">
                    <div class="triangle"></div>
                    <a class="dropdown-item" [routerLink]="['/my-account']"><i class="fa fa-cog" aria-hidden="true"></i> 
                      My Account</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" (click)="logout()" href="javascript:void(0)">
                      <i class="fa fa-power-off" aria-hidden="true"></i> Logout</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>-->


<header class="revamp-header main-header">
  <div class="container-fluid">
    <div class="row">
        <div class="col-lg-4 align-self-center">
            <a (click)="goToProjects()">            
                <img class="revamp-logo" src="assets/images/main-logo.svg" alt="Builder360" title="Builder360">
            </a>
        </div>
        <div class="col-lg-8">
            <div *ngIf="checkUserLogged()" class="head-right profile-hide profile-show">
              <!-- <div class="notifications">
                <i class="fa fa-bell-o" aria-hidden="true"></i>
                <span class="notifications-count">02</span>
              </div> -->
              <div class="manager_availability_status"> 
                <div class="status_label">
                  <ul class="list-inline">
                    <li class="badge-label">Status:</li>
                    <li class="badge-stl" (click)='statusShow(statusShowHide, $event)'>
                      <div class="item-status-manager" [ngClass]='{"available-status" : commonService.currentStatus === "Available", "not-available-status" :  commonService.currentStatus === "Not Available", "do-not-disturb-status" :  commonService.currentStatus === "Do Not Disturb" }'>{{commonService.currentStatus}}</div>
                      <em class="zmdi zmdi-chevron-down cstm-down"></em>                                  
                    </li>
                  </ul>
                  <div class="manager-status-dropdown" [ngClass]='{"available-status" : commonService.currentStatus === "Available", "not-available-status" :  commonService.currentStatus === "Not Available", "do-not-disturb-status" :  commonService.currentStatus === "Do Not Disturb" }'> 
                    <div class="dropdown">  
                      <div class="dropdown-menu" [ngClass]='{"show" : statusShowHide, "hide" : !statusShowHide}'> 
                        <div class="dropdownMenuInner">
                          <div class="triangle"></div>
                          <div class="item-status-manager" [ngClass]='{"available-status" : status === "Available", "not-available-status" :  status === "Not Available", "do-not-disturb-status" :  status === "Do Not Disturb" }' *ngFor='let status of statusArray;' (click)='statusUpdate(status)'>{{status}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="member-profile">
                <div class="profileBlock" (click)= 'toggleHeaderDropDown($event)'>
                <div class="initial-circle">
                  <span>{{ (userData?.first_name?.charAt(0) + userData?.last_name?.charAt(0)) | uppercase }}</span>
                </div>
                <button class="btn btn-secondary  profile-arr" type="button" id="dropdownMenuButton"                     
                  aria-haspopup="true" aria-expanded="false">
                  <em class="zmdi zmdi-chevron-down profile-arr-in"></em>
                </button>
                </div>  
                <div class="dropdown">
                  <div class="dropdown-menu" [ngClass]='{"show": isShowHeaderDropDown}' >
                    <!-- <div class="profile-detail">
                      <div class="profile-detail-img">
                        <img [src]="userData.profile_pic.url ? (commonService.updatedUserImage ? commonService.updatedUserImage : userData.profile_pic.url ) : 'assets/images/default-profile.png'"
                            alt="">
                      </div>
                      <p>{{ userData?.first_name +' '+ userData.last_name }}<br> <span>{{ (userData?.role === 'pm') ? 'PM' : 'PM Lead' }}</span></p>
                    </div> -->
                    <!-- <div class="dropdown-divider"></div> -->
                    <div class="dropdownMenuInner">
                        <div class="triangle"></div>
                        <a class="dropdown-item" [routerLink]="['/my-account']"><i class="fa fa-cog" aria-hidden="true"></i> 
                          My Account</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" (click)="logout()" href="javascript:void(0)">
                          <i class="fa fa-power-off" aria-hidden="true"></i> Logout</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>        
    </div>
  </div>  
</header>



<div bsModal #SpecStatusModal="bs-modal" class="client-modal modal fade" id="SpecStatusModal" tabindex="-1" role="dialog"
     aria-labelledby="modalCenterTitle"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal">
      <div class="modal-body">
        <p>Looks like you are already blocked for an instant spec call. Do you want to cancel ongoing request?</p>
        <p>Let us know why...</p>
        <div class="custom-radio">
          <div class="radio radio-primary"  *ngFor="let reason of reasonArray; let i = index">
            <input type="radio" id="early_ended" name="change_spec_status" [checked]="i === selectedIndex" id="{{ reason.value }}" [value]="reason.value"
             (change)="temp(reason.value, i)"> <label for="{{reason.value}}">{{reason.name}}</label>
          </div>
          <!-- <div class="radio radio-primary">
            <input type="radio"id="drop_off" name="change_spec_status" [value]='false'  (change)="temp('customerDropped')"> <label for="drop_off">Customer dropped off</label>
          </div> -->
        </div>        
        <div class="form-group d-flex justify-content-end btn-block">
          <input class="btn cancel-btn" type="reset" name="" value="Cancel" (click)="closeSpecStatusModal()">
          <input class="btn add-btn" type="submit" name="" value="Confirm" (click)='confirmStatusChange()'>
        </div>
      </div>
    </div>
  </div>
</div>