import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { ApiRequests } from '../../../shared/shared-module/services/apiRequests.service';
import { Angulartics2Segment } from 'angulartics2/segment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {

  @ViewChild('email') public email;
  @ViewChild('password') public password;
  public login_data = { email: '', password: '' };
  public loginPass = { show: false };
  public CommonErrorMsg = '';
  public rememberMe = false;
  public resetSucess = false;

  constructor(public commonService: CommonService,
    public dataService: DataService,
    public router: Router,
    public apiRequest: ApiRequests,
    public angulartics2Segment: Angulartics2Segment) {
  }

  public ngOnInit() {
    if (this.dataService.getUserData()) {
      document.cookie = 'internal_employee=true';
      this.commonService.newHeadertrue = false;
      if(localStorage.getItem('zoomAuthCode')){
        this.router.navigate(['my-account']);
      }else if (localStorage.getItem('google_cal_auth')){
          localStorage.removeItem('google_cal_auth');
          this.router.navigate(['my-account']);
      }else{
        this.router.navigate(['projects']);
      }
    } else {
      this.commonService.newHeadertrue = true;
    }
    this.rememberMe = this.dataService.getAutoComplete();
  }

  public checkIfEmailValid(): boolean {
    return this.email && this.email.errors && (this.email.touched || this.email.dirty);
  }

  public ispasswordError(): boolean {
    return (this.password.touched || this.password.dirty) && this.password.errors;
  }

  public passwordLengthCheck(): boolean {
    return this.password && this.password.errors.minlength && !this.ispasswordError();
  }

  public passwordRequiredCheck(): boolean {
    return this.password && this.password.errors.required;
  }

  public checkLoginValidity(): boolean {
    return this.checkIfEmailValid() && this.passwordLengthCheck() && this.passwordRequiredCheck();
  }

  public showPassword() {
    this.loginPass.show = !this.loginPass.show;
  }

  public loginClick(form: FormGroup) {
    this.angulartics2Segment.eventTrack('Sign up clicked', '');
    this.login_data.email = this.login_data.email.toLowerCase();
    this.trackLoginEvent('login clicked', this.commonService.setUserProperties(this.login_data));
    this.apiRequest.login_signup(this.login_data, 'login').subscribe((data: any) => {
      this.resetSigninForm();
      this.resetSucess = false;
      if (this.rememberMe) {
        this.dataService.storeAutoComplete(this.rememberMe ? 'true' : 'false');
      }
      this.commonService.resetSucess = false;
      this.commonService.newHeadertrue = false;
      this.commonService.notifyMyAccountDetail(data);
      this.setUserPropertiresToSegment(data.data.user);
      this.trackLoginEvent('login successful', this.commonService.setUserProperties(this.login_data));
      document.cookie = 'internal_employee=true';
      this.router.navigate(['projects']);
    }, error => {
      this.trackLoginEvent('login unsuccessful', this.commonService.setUserProperties(this.login_data));
      this.CommonErrorMsg = error.error;
    });
  }


  trackLoginEvent(eventName, properties) {
    this.angulartics2Segment.eventTrack(eventName, properties);
  }

  setUserPropertiresToSegment(data) {
    this.angulartics2Segment.setUserProperties(this.commonService.setUserProperties(data));
  }

  public rememberMeClick() {
    this.rememberMe = !this.rememberMe;
  }

  public resetSigninForm() {
    this.login_data = { email: '', password: '' };
  }

  public checkAutoComplete() {
    if (this.rememberMe) {
      return 'on';
    } else {
      return 'off';
    }
  }

  public getresetVar() {
    setTimeout(() => {
      this.commonService.resetSucess = false;
    }, 7000);
    return this.commonService.resetSucess;
  }

  public removeErrMsg() {
    this.CommonErrorMsg = '';
  }

}
