import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-code-repo',
  templateUrl: './code-repo.component.html',
  styleUrls: ['./code-repo.component.scss']
})
export class CodeRepoComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
