
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequests } from './http-requests.service';
import { Constants } from '../utility/constants';
import { DataService } from './data.service';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
@Injectable()
export class ApiRequests {
  constructor(public httpRequest: HttpRequests, public dataService: DataService) {

  }

  public login_signup(userdetails, option) {
    let API;
    if (option === 'login') {
      API = this.dataService.getServerUrl() + Constants.apiEndPoints.login;
      if (window.location.href.indexOf('invited') > -1) {
        const queryParam = window.location.href.split('?');
        API = this.dataService.getServerUrl() + Constants.apiEndPoints.login + '?' + queryParam[1];
      }
    } else if (option === 'signup') {
      API = this.dataService.getServerUrl() + Constants.apiEndPoints.signup;
    } else if (option === 'forgot') {
      API = this.dataService.getServerUrl() + Constants.apiEndPoints.forgot;
    } else if (option === 'reset') {
      API = this.dataService.getServerUrl() + Constants.apiEndPoints.reset;
    }
    if (userdetails.email) {
      userdetails.email = userdetails.email.toLowerCase();
    }
    return this.httpRequest.post(API, JSON.stringify(userdetails), this.dataService.getRequestOptionArgs('application/json'))
      .map((res) => {
        if (option === 'forgot' || option === 'reset' || option === 'signup') {
          return res;
        } else {
          this.dataService.user = res.data.user;
          this.dataService.authToken = res.data['auth-token'];
          this.dataService.storeUserObject();
          return res;
        }
      }).catch((error: any) => {
        this.dataService.user = null;
        return observableThrowError(error);
      });
  }

  public updateUserDetails(userdetails) {
    const API = this.dataService.getServerUrl() + Constants.apiEndPoints.update_user_details + this.dataService.user.id;
    return this.httpRequest.patch(API, JSON.stringify(userdetails), this.dataService.getRequestOptionArgs('application/json'))
      .map((res) => {
        this.dataService.user = res.data.user;
        this.dataService.storeUserObject();
        return res;
      }).catch((error: any) => {
        return observableThrowError(error);
      });
  }

  public uploadProfilePic(profilePic) {
    const data = new FormData();
    data.append('user[profile_pic]', profilePic);
    const API = this.dataService.getServerUrl() + Constants.apiEndPoints.update_user_details + this.dataService.user.id;
    return this.httpRequest.patch(API, data, this.dataService.getRequestOptionArgs())
      .map((res) => {
        this.dataService.user = res.data.user;
        this.dataService.storeUserObject();
      }).catch((error: any) => {
        return observableThrowError(error);
      });
  }

  public changePassword(userdetails) {
    const API = this.dataService.getServerUrl() + Constants.apiEndPoints.changepassword;
    return this.httpRequest.post(API, JSON.stringify(userdetails), this.dataService.getRequestOptionArgs('application/json'))
      .map((res) => {
        return res;
      }
      ).catch((error: any) => {
        return observableThrowError(error.json());
      });
  }

  public getUserData() {
    return this.dataService.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  }

  public getUpdatedUserData() {
  if( this.dataService.user== null){
    return observableThrowError('Please try again!');
  }
  const API = this.dataService.getServerUrl() + 'users/' + this.dataService.user.id;
  return this.httpRequest.get(API, this.dataService.getRequestOptionArgs())
  .map((res) => {
    this.dataService.user = res.data.user;
    localStorage.setItem('user', JSON.stringify(res.data.user));

    localStorage.setItem('authToken', this.dataService.authToken);
  }).catch((error: any) => {
    return observableThrowError(error);
  });
  }

  public getAuthToken() {
    this.dataService.authToken = localStorage.getItem('authToken') ? localStorage.getItem('authToken') : '';
  }

  fetchPartnerDetails() {
    const API = this.dataService.getServerUrl() + Constants.apiEndPoints.fetch_partners_details;
    return this.httpRequest.get(API)
      .map((res) => {
        this.dataService.partnerData = res.data.partner;
        this.dataService.projectData = res.data.platform_details;
        this.dataService.releaseStatusList = res.data.release_status;
        localStorage.setItem('partnerData', JSON.stringify(res.data.partner));
        localStorage.setItem('platformDetails', JSON.stringify(res.data.platform_details));
        localStorage.setItem('release_status', JSON.stringify(res.data.release_status));
      }).catch((error: any) => {
        return observableThrowError(error);
      });
  }

  logoutSession() {
    const API = this.dataService.getServerUrl() + 'sessions';
    return this.httpRequest.delete(API, this.dataService.getRequestOptionArgs('application/json'))
      .map((res) => {
        if (res) {
          return res;
        }
        return null;
      }).catch((error: any) => {
        return Observable.throw(error);
      });
  }

  createDates(dates) {
    const API = this.dataService.getServerUrl() + 'users/' + this.dataService.user.id + '/available_dates';
    return this.httpRequest.post(API, dates, this.dataService.getRequestOptionArgs('application/json'))
      .map((res) => {
        return res;
      }
      ).catch((error: any) => {
        return observableThrowError(error.json());
      });
  }

  updateData(id, startTime?, endTime?, date?, editId?,delData?) {
    let available_date;
    if(startTime && endTime && !editId && !delData) {
       available_date = {
        "available_date": {
        "time_slots_attributes": [
          {	
            "from": startTime,
            "to": endTime
          }
        ]
      }
      }
    } else if (startTime && endTime && editId && !delData){
      available_date = {
        "available_date": {
        "time_slots_attributes": [
          {	
            "id" : editId,
            "from": startTime,
            "to": endTime
          }
        ]
      }
      }
    }  
    
    if (delData) {
          available_date = delData;
     }

    if(date) {
      available_date = date;
    }
    const API = this.dataService.getServerUrl() + 'users/' + this.dataService.user.id + '/available_dates/' + id;
    return this.httpRequest.patch(API, available_date, this.dataService.getRequestOptionArgs())
      .map((res) => {
        return res;
      }
      ).catch((error: any) => {
        return observableThrowError(error.json());
      });
  }

  public deleteCal(id) {
    const API = this.dataService.getServerUrl() + 'users/' + this.dataService.user.id + '/available_dates/' + id;
    return this.httpRequest.delete(API, this.dataService.getRequestOptionArgs())
      .map((res) => {
        return res;
      }
      ).catch((error: any) => {
        return observableThrowError(error.json());
      });
  }
}
