<section class="middle-sec common-redesign">
  <div class="pm-dashboard-modal">
    <h2>Reset Password</h2>
    <form class="common-form" #resetform = "ngForm" name="resetform">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="new_pass">Enter New Password</label>
            <div class="common-input-box sa-password-box">
              <input id="new_pass" type="{{hideShow.passShow?'text':'password'}}" name="password" class="common-input" required
                   maxlength="100" minlength="8"
                   #password="ngModel" [(ngModel)]="data.password" autocomplete="off"/>
                   <i [ngClass]="{'active': hideShow.passShow }" class="clear-icon" aria-hidden="true" (click)="hideShow.passShow=!hideShow.passShow"
                   *ngIf="data.password"></i>
            </div>       
            <!--<label>
              <span>Enter New Password
                  <span>*</span>
              </span>
            </label>-->
            <div *ngIf="ispasswordError()">
              <div class="invalid-feedback" *ngIf="password.touched && password.errors && (password.errors.minlength) && (!checkSpacePassword())">Password should contain 8 characters</div>
              <div class="invalid-feedback" *ngIf="passwordRequiredCheck()">Password is required</div>
              <div class="invalid-feedback" *ngIf="checkSpacePassword()">Password doesn't contain spaces</div>
              
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb0">
            <label for="confirm_pass">Enter Confirm Password</label>
            <div class="common-input-box sa-password-box">
              <input  id="confirm_pass" type="{{hideShow.confirmShow?'text':'password'}}" name="cpassword" class="common-input" required
                autocomplete="off" maxlength="100" minlength="8"
                #cpassword="ngModel" [(ngModel)]="data.password_confirmation"/>
                <i [ngClass]="{'active': hideShow.confirmShow }" class="clear-icon" aria-hidden="true" (click)="hideShow.confirmShow=!hideShow.confirmShow"
                *ngIf="data.password"></i>                
            </div>                  
            <!--<label>
                          <span>Enter confirm password
                              <span>*</span>
                          </span>
            </label>-->
            <div *ngIf="ispasswordError(true)">
              <div class="invalid-feedback" *ngIf="confirmPassword.touched && confirmPassword.errors && confirmPassword.errors.minlength && !checkSpaceCpassword()">Password should contain 8 characters</div>
              <div class="invalid-feedback" *ngIf="passwordRequiredCheck(true)">Password is required</div>            
              <!-- <div class="invalid-feedback" *ngIf="checkSpaceCpassword()">Password doesn't contain spaces</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mt0">
            <span class="invalid-feedback">{{CommonErrorMsg}}</span>
            <p class="tick" *ngIf="checkPass()">Password Match</p>
            <p class="passwordNotMatch invalid-feedback" *ngIf="checkPass() === false"><i class="fa fa-times-circle"></i>
              Passwords do not match</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group m12">
            <input type="submit" class="btn btn-primary comm-login-module-btn" name="submit" value="Reset"
                   (click)="resetClick()" [disabled]="resetform.invalid || checkPass() === false">
          </div>
        </div>
      </div>
    </form>
    <p>Back to
      <a [routerLink]="['/login']">Login</a>
    </p>
  </div>
</section>

