<section class="middle-dashboard">
  <div class="right-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="main-inner-block">
            <div class="projects-block manager-block">
              <div class="projects-search-filter">
                <form class="projects-search">
                  <input type="submit" name="" value="">
                  <input type="text" name="" value="" placeholder="Search">
                </form>
                <ul class="sort-filter-list">
                  <li>
                    <a href="javascript: void(0)">
                      <img src="assets/images/filter.png" alt=""> Filter
                    </a>
                  </li>
                  <li>
                    <a href="javascript: void(0)">
                      <img src="assets/images/sort.png" alt=""> Sort by
                    </a>
                  </li>
                </ul>
              </div>
              <div class="row pt-1 pb-1">
                <div class="col-lg-12">
                  <div class="projects-tab">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link blocked-tab-color active" id="blocked-tab" data-toggle="tab" href="#blocked"
                           role="tab" aria-selected="true">Managers</a>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="blocked" role="tabpanel" aria-labelledby="blocked-tab">
                        <div class="row">
                          <div class="col-lg-3 new-project-block" data-toggle="modal" data-dismiss="modal"
                               data-target="#addManagerModal">
                            <div class="project-card manager-card create-new-project">
                              <div class="new-project-inner">
                                <img src="assets/images/add-icon.png" alt="">
                                <p>Add New Manager</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 offset-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Super Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle yellow"></i> Status
                                  <strong>In Active
                                    <span>4 days</span>
                                  </strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle green"></i> Status
                                  <strong>Active</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Super Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle yellow"></i> Status
                                  <strong>In Active
                                    <span>4 days</span>
                                  </strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle green"></i> Status
                                  <strong>Active</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Super Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle yellow"></i> Status
                                  <strong>In Active
                                    <span>4 days</span>
                                  </strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle green"></i> Status
                                  <strong>Active</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Super Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle yellow"></i> Status
                                  <strong>In Active
                                    <span>4 days</span>
                                  </strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle green"></i> Status
                                  <strong>Active</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Super Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle yellow"></i> Status
                                  <strong>In Active
                                    <span>4 days</span>
                                  </strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle green"></i> Status
                                  <strong>Active</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="project-card manager-card">
                              <h4 class="block-title">Manager Name</h4>
                              <p class="edit-client" data-dismiss="modal" data-toggle="modal"
                                 data-target="#editManagerModal">
                                <i aria-hidden="true" class="fa fa-pencil"></i> Edit</p>
                              <ul class="project-card-list">
                                <li>Email :
                                  <strong>Priynaka.kochhar@Engineer.ai</strong>
                                </li>
                                <li>
                                  <i class="fa fa-map-marker" aria-hidden="true"></i> Bangalore
                                </li>
                                <li>Phone Number :
                                  <strong>+ 91 9869874586</strong>
                                </li>
                                <li>Designation :
                                  <strong>Projet Manager</strong>
                                </li>
                                <li>Department :
                                  <strong>Department Name</strong>
                                </li>
                              </ul>
                              <ul class="project-card-bottom-list">
                                <li>No. of Projects
                                  <strong>05</strong>
                                </li>
                                <li>Role
                                  <strong>Super Admin</strong>
                                </li>
                                <li>
                                  <i aria-hidden="true" class="fa fa-circle yellow"></i> Status
                                  <strong>In Active
                                    <span>4 days</span>
                                  </strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--Add Manager-->
<div class="client-modal modal fade" id="addManagerModal" tabindex="-1" role="dialog" aria-labelledby="modalCenterTitle"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal">
      <div class="modal-body">
        <h2>Add New Manager</h2>
        <form>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-6">
                <label>First Name
                  <span>*</span>
                </label>
                <input class="form-control mb-sm-3 mb-lg-auto" type="text" placeholder="First Name">
              </div>
              <div class="col-lg-6">
                <label>Last Name
                  <span>*</span>
                </label>
                <input class="form-control" type="text" placeholder="Last Name">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Email
              <span>*</span>
            </label>
            <input class="form-control" type="email" placeholder="clientemail@gmail.com">
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-4">
                <label>Country Code</label>
                <input class="form-control mb-sm-3 mb-lg-auto" type="text" placeholder="+91">
              </div>
              <div class="col-lg-8">
                <label>Phone Number</label>
                <input class="form-control" type="text" placeholder="9865878956">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Role
              <span>*</span>
            </label>
            <div class="select-box">
              <select class="form-control">
                <option>Super Admin</option>
                <option>Admin</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label>Designation</label>
            <input class="form-control" type="text" placeholder="Project Manager">
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-6">
                <label>Department</label>
                <div class="select-box half mb-sm-3 mb-lg-auto">
                  <select class="form-control">
                    <option>Select Department</option>
                    <option>Select Department</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <label>Office</label>
                <div class="select-box half">
                  <select class="form-control">
                    <option>Bangaluru</option>
                    <option>Mumbai</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group d-flex justify-content-end btn-block">
            <input class="btn cancel-btn" type="reset" name="" value="Cancel">
            <input class="btn add-btn" type="submit" name="" value="Send Invite">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<!--Edit Manager-->
<div class="client-modal modal fade" id="editManagerModal" tabindex="-1" role="dialog"
     aria-labelledby="modalCenterTitle"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal">
      <div class="modal-body">
        <h2>Edit Manager</h2>
        <form>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-6">
                <label>First Name
                  <span>*</span>
                </label>
                <input class="form-control mb-sm-3 mb-lg-auto" type="text" placeholder="First Name">
              </div>
              <div class="col-lg-6">
                <label>Last Name
                  <span>*</span>
                </label>
                <input class="form-control" type="text" placeholder="Last Name">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Email
              <span>*</span>
            </label>
            <input class="form-control" type="email" placeholder="clientemail@gmail.com">
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-4">
                <label>Country Code</label>
                <input class="form-control mb-sm-3 mb-lg-auto" type="text" placeholder="+91">
              </div>
              <div class="col-lg-8">
                <label>Phone Number</label>
                <input class="form-control" type="text" placeholder="9865878956">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Role
              <span>*</span>
            </label>
            <div class="select-box">
              <select class="form-control">
                <option>Super Admin</option>
                <option>Admin</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label>Designation</label>
            <input class="form-control" type="text" placeholder="Project Manager">
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-6">
                <label>Department</label>
                <div class="select-box half mb-sm-3 mb-lg-auto">
                  <select class="form-control">
                    <option>Select Department</option>
                    <option>Select Department</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <label>Office</label>
                <div class="select-box half">
                  <select class="form-control">
                    <option>Bangaluru</option>
                    <option>Mumbai</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group d-flex justify-content-end btn-block">
            <input class="btn cancel-btn" type="reset" name="" value="Cancel">
            <input class="btn add-btn" type="submit" name="" value="Save Changes">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
