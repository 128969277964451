import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { ProjectStatusService } from '../../services/project-status.service';
import { DataService } from '../../../shared/shared-module/services/data.service';

@Component({
  selector: 'app-main-leftpanel',
  templateUrl: './main-leftpanel.component.html',
  styleUrls: ['./main-leftpanel.component.css']
})
export class MainLeftpanelComponent implements OnInit {
  public currentSelection = '/projects';
  isPartnersShow = true;
  loginData: any;
  constructor(public projectStatusService: ProjectStatusService, public router: Router, public commonService: CommonService,
    public cdr: ChangeDetectorRef, public dataService: DataService) {
      const partnerData = JSON.parse(localStorage.getItem("partnerData"));
      if ( partnerData && !partnerData.vendor_backend_domain) {
        this.isPartnersShow = false;
      }
    }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = event.url;
        if (url && url.includes('/projects')) {
          if(!(url.includes('/project-allocation')  )){
            localStorage.removeItem('projectAllocationTab');
          }
          url = '/projects';
         
        }

        if (url !== '/projects') {
          this.commonService.leftPanelOpen = false;
          this.commonService.showHomePage = true;
        }
        this.currentSelection = url;
      }

    });
    this.loginData = this.getUserData();
    if (!this.loginData) {
      this.commonService.newHeadertrue = true;
      return;
    } else {
      // this.router.navigate(['/projects']);
      this.commonService.newHeadertrue = false;
    }
  }

  public changeSection(sectionName) {
    this.projectStatusService.projectTabStatus = 'pending';
    this.commonService.leftPanelOpen = false;
    this.commonService.showHomePage = true;
    this.currentSelection = sectionName;
    if (sectionName === '/partners') {
      this.commonService.notifyPartnersListDetail(true);
    }
  }

  public getUserData() {
    return this.dataService.user;
  }

}
