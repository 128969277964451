
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { DataService } from "./data.service";
import { CommonService } from './common.service';

@Injectable()
export class HttpRequests {
  constructor(public commonService: CommonService, public http: HttpClient, private router: Router, private dataService: DataService) {
  }

  public get(url: string, Headers?, requestOptions?): Observable<any> {
    if (requestOptions) {
      return this.http.get(url, requestOptions)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    } else if (Headers) {
      return this.http.get(url,  Headers )
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    } else {
      return this.http.get(url)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    }
  }


  public post(url: string, data: any, Headers): Observable<any> {
    if (Headers) {
      return this.http.post(url, data,  Headers )
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    } else {
      return this.http.post(url, data)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    }
  }

  public put(url: string, data: any, Header): Observable<any> {
    if (Header) {
      return this.http.put(url, data,  Header)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    } else {
      return this.http.put(url, data)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    }
  }

  public delete(url: string, Header): Observable<any> {
    if (Header) {
      return this.http.delete(url, Header)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    } else {
      return this.http.delete(url)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    }
  }

  public patch(url: string, data: any, Header): Observable<any> {
    if (Header) {
      return this.http.patch(url, data, Header)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    } else {
      return this.http.patch(url, data)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          this.checkErrorStatus(error);
          return observableThrowError(error.error);
      });
    }
  }

  public parseResponse(res: any): any {
    return res;
  }

  public handleError(error: any) {
    this.checkErrorStatus(error);
    return observableThrowError(error.error);
  }

  public checkErrorStatus(error) {
    if (error.status === 401) {
      this.dataService.logout();
      this.router.navigate(['unauthorized-access']);
    } else if (error.status === 500) {
      this.router.navigate(['under-maintainance']);
    } else if (error.status === 404) {
      if (this.dataService.getAuthToken()) {
        this.router.navigate(['page-not-found']);
      }

    }
    return observableThrowError(error.error);
  }
}
