import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
//services
import { ApiRequests } from './services/apiRequests.service';
import { CommonService } from './services/common.service';
import { HttpRequests } from './services/http-requests.service';
import { DataService } from './services/data.service';
import { MomentModule } from 'angular2-moment';
import { ModelMapperService } from './services/model-mapper.service';
import { FilterPipe } from './pipes/filter.pipe';
import { FilenamePipe } from './pipes/filename.pipe';
import { CommentNamePipe } from './pipes/comment.name.pipe';
import { SingleLastNamePipe } from './pipes/single.last.name.pipe.';
import { ProjectChecklistCodeRepoFormComponent } from './components/project-checklist-code-repo-form/project-checklist-code-repo-form.component';
import { ModalDirective, ModalModule } from 'ngx-bootstrap';
import { SharedService } from './services/shared.service';
import { ModalComponent } from './components/modal/modal.component';
import { OrderByPipe } from './pipes/order-by.pipe';


//components
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MomentModule,
    ModalModule.forRoot()
  ],
  declarations: [ModalComponent,
    ProjectChecklistCodeRepoFormComponent,
    FilterPipe, FilenamePipe, CommentNamePipe,OrderByPipe,
    SingleLastNamePipe, ModalComponent],
  exports: [ModalComponent,
    ProjectChecklistCodeRepoFormComponent, FilterPipe, FilenamePipe, CommentNamePipe, OrderByPipe, SingleLastNamePipe, ModalModule]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [CommonService, SharedService, ApiRequests, HttpRequests, DataService, ModelMapperService]
    };
  }
}
