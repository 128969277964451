import {Injectable} from "@angular/core";
import {JsonConvert, OperationMode, ValueCheckingMode} from "json2typescript";
import {UrlParameterModel} from "../models/url-parameters-model/url-parameter.model";
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
/**
 * Created by nitin on 16/12/17.
 */
@Injectable()
export class ModelMapperService {
  public jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();
    this.jsonConvert.operationMode = OperationMode.ENABLE; // print some debug data
    this.jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // allow null
  }

  public mapToUrlParameterModel(mappedObj: UrlParameterModel, jsonObject): UrlParameterModel {
    try {
      // mappedObj = this.jsonConvert.deserialize(jsonObject, UrlParameterModel);
     if(jsonObject['code'] &&jsonObject['code'].indexOf('#/my-account')>-1 ){
       console.log('settn auth code')
        localStorage.setItem('zoomAuthCode',jsonObject['code'].split('#')[0]);
     }

      return mappedObj;
    } catch (e) {
      console.log((<Error>e));
    }
  }
}
