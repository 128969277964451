import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { rootRouterConfig } from '../../app.route';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SignupComponent } from '../login-module/signup/signup.component';
import { LoginComponent } from '../login-module/login/login.component';
import { ForgotPasswordComponent } from '../login-module/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../login-module/reset-password/reset-password.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared-module/shared.module';
import { MainLeftpanelComponent } from './main-leftpanel/main-leftpanel.component';
import { CodeRepoComponent } from './code-repo/code-repo.component';
import { BuildReleaseComponent } from './build-release/build-release.component';
import { ManagerComponent } from './manager/manager.component';
import { ManagerDetailComponent } from './manager-detail/manager-detail.component';
import { UrlParameterModel } from '../../shared/shared-module/models/url-parameters-model/url-parameter.model';
import { ModelMapperService } from '../../shared/shared-module/services/model-mapper.service';
import { DataService } from '../../shared/shared-module/services/data.service';
import { LoaderService } from '../services/loader.service';
import { PartnerBasicInformationComponent } from './partner-basic-information/partner-basic-information.component';
import { ProjectStatusService } from '../services/project-status.service';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { ToastMessageService } from '../services/toast.message.service';
import { AuthGuard } from "../../auth-guard.service";
import { ApiRequests } from "../../shared/shared-module/services/apiRequests.service";
// import { ProjectLeftPanelComponent } from './project-left-panel/project-left-panel.component';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Segment } from 'angulartics2/segment';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UnauthorizedAccessComponent } from './unauthorized-access/unauthorized-access.component';
import { UnderMaintainanceComponent } from './under-maintainance/under-maintainance.component';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { GlobalErrorHandler } from '../services/global-error-handler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RevampHeaderComponent } from './revamp-header/revamp-header.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SignupComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    MainLeftpanelComponent,
    CodeRepoComponent,
    BuildReleaseComponent,
    ManagerComponent,
    ManagerDetailComponent,
    PartnerBasicInformationComponent,
    PageNotFoundComponent,
    UnauthorizedAccessComponent,
    UnderMaintainanceComponent,
    RevampHeaderComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(rootRouterConfig, { onSameUrlNavigation: 'reload', useHash: true , preloadingStrategy:PreloadAllModules }),
    FormsModule,
    SharedModule,
    SharedModule.forRoot(),
    HttpClientModule,
    Angulartics2Module.forRoot([Angulartics2Segment]),
    AngularMyDatePickerModule,
    InternationalPhoneNumberModule,
    BrowserAnimationsModule
  ],
  providers: [AuthGuard, LoaderService, ProjectStatusService, ToastMessageService,{provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public modelMapperService: ModelMapperService,
    public dataService: DataService,
    private apiRequests: ApiRequests) {
    dataService.setIncomingUrlParameters(this.getUrlParamsFromUrl());
    this.apiRequests.fetchPartnerDetails().subscribe();
  }
  public getUrlParamsFromUrl(): UrlParameterModel {
    const path = location.href;
    let query;
    if (path.indexOf('?') !== -1) {
      if (path.indexOf('auth_type=zoomlogin') > -1) {
        let auth = path.split('&')[1];
        if (auth.indexOf('code=') > -1) {
          let authCode = auth.split('=')[1];
          if (authCode != null && authCode != '') {
            localStorage.setItem('zoomAuthCode', authCode);
          }

        }
      }

      if(path.indexOf('auth_type=googlecalenderlogin')>-1){
        localStorage.setItem('google_cal_auth','true');
        if(path.indexOf('error=')==-1){
          let params = path.split('&');
          for (let i in params ){
           
            if(params[i].indexOf('code')>-1){
              console.log(params[i]);
              localStorage.setItem('google_cal_auth_code',params[i].split('=')[1])
            }
          }
        } else {
          localStorage.setItem('google_cal_auth_error','true');
        }
       
      }
      query = path.substr(path.indexOf('?') + 1);
      const result = {};
      query.split('&').forEach((part) => {
        const item = part.split('=');
        if (item[0] !== '') {
          result[item[0]] = decodeURIComponent(item[1]);
        }
      });
      return this.modelMapperService.mapToUrlParameterModel(new UrlParameterModel(), result);
    }
    return null;
  }

}
