<ul class="left-nav">
  <li>
    <a [routerLink]="['/projects']" (click)="changeSection('/projects')" class="projects-icon"
       [ngClass]="{active: currentSelection === '/projects'}">
      Projects
    </a>
  </li>
  <li>
    <a [routerLink]="['/spec-calls']" (click)="changeSection('/spec-calls')" class="spec-icon"
        [ngClass]="{active: currentSelection === '/spec-calls'}">
        Studio Calls
    </a>
  </li>  
  <li *ngIf = "isPartnersShow">
    <a [routerLink]="['/partners']" (click)="changeSection('/partners')" class="partners-icon"
       [ngClass]="{active: currentSelection === '/partners'}">
      Partners
    </a>
  </li>
  <li>
    <a *ngIf="getUserData()?.role === 'pm_lead'" [routerLink]="['/managers']" (click)="changeSection('/managers')" class="managers-icon"
       [ngClass] = "{active: currentSelection === '/managers'}">
      Managers
    </a>
  </li>
   <li>
    <a [routerLink]="['/accounts']" (click)="changeSection('/accounts')" class="accounts-icon" 
       [ngClass] = "{active: currentSelection === '/accounts'}">
      Accounts
    </a>
  </li> 
  <li>
      <a [routerLink]="['/repository/folder']" (click)="changeSection('/repository')" class="repository-icon" [ngClass] = "{active: currentSelection.indexOf('repository') !=-1}">
          Repository
      </a>
  </li>
</ul>
