import { Injectable } from "@angular/core";

@Injectable()
export class ToastMessageService {

  public showToastMsg = false;
  public toastMessage: string;

  constructor() {
  }

  public showToastMessage(message, time?) {
    time = time ? time : 2000;
    this.toastMessage = message;
    this.showToastMsg = true;
    setTimeout(() => {
      this.showToastMsg = false;
    }, time);
  }

}
