export class Constants {
  static apiEndPoints = {
    signup: 'registrations/sign_up',
    login: 'sessions/sign_in',
    code_repo: 'code_repos',
    forgot: 'registrations/forgot_password',
    reset: 'registrations/reset_password',
    get_post: 'projects',
    client: 'clients',
    manager: 'admins',
    inviteManager : 'admins/invite',
    update_user_details: 'users/',
    changepassword: 'users/change_password',
    todos: 'todos/',

    timezone: 'timezones',
    getAssigneeList: 'projects/[project_id]/todos/new',
    getMeetings: 'projects/[project_id]/meetings',
    createBuild: 'projects/[project_id]/builds',
    createTodo: 'projects/[project_id]/todos',
    updateTodo: 'projects/[project_id]/todos/',
    updateMeeting: 'projects/[project_id]/meetings/',
    getTodos: 'projects/[project_id]/todos?filter=',
    getComments: 'todos/[todo_id]/comments',
    getTimeline: 'todos/[task_id]/timeline',
    projectAssign: 'projects/assign',
    getEpicActivity: 'projects/[project_id]/trackers/epic_feed',
    update_projects: 'projects/[project_id]',
    fetch_partners_details: 'partners/show',
    getideaboards: 'projects/[project_id]/ideaboards?filter=',
    getVideoTags: 'projects/[project_id]/meetings/[meeting_id]/feature_taggings',
    getAllPods: 'pods'
  };
  static userRole = {
    pm_lead: 'pm_lead'
  };
  static API_URL = {
    isProduction: 'http://34.227.230.48/api/v1/pm/',
    isStaging: 'http://34.227.230.48/api/v1/pm/'
  };
  static PARAM_CONSTANTS = {
      DECIMAL: '.'
  };
  static VIDEO_MIN_SNIP_TIME = {
    TIME: 2
};
static ROR_REACT_ICONS = {
  RUBY: 'https://pmdashboardassets.blob.core.windows.net/assets-pm-production/Ruby.svg',
  REACT: 'https://pmdashboardassets.blob.core.windows.net/assets-pm-production/React.svg'
}
}
