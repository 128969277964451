import { Injectable, EventEmitter } from '@angular/core';
import { ProjectModel } from '../models/project-model/project.model';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ProjectMeetingEditComponent } from '../../../core/project-module/project-meetings-module/project-meeting-edit/project-meeting-edit.component';
import { Constants } from '../utility/constants';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import { Angulartics2Segment } from 'angulartics2/segment';
@Injectable()
export class CommonService {

  public leftPanelOpen = false;
  public showHomePage = false;
  public guidelineSlider = false;
  // public showToastMsg = false;
  // public toastMessage: string;
  public locale = 'en-US';
  public currentStatus = '';
  public errorZohoId = '';
  public updatedUserImage = '';
  public ifViewTeamClicked = false;
  public rateValue: string;
  public skillValue: any;
  public managerListing = true;
  public selectedProjecId;
  public selectedManagerId;
  public resetSucess = false;
  public tabStatus = 'pending';
  public meetingTabStatus = 'today';
  public newHeadertrue = false;
  // public todosQueries = [];
  public projectDetail: ProjectModel;
  public blockedReasonKey = false;
  updateProjectDetail = new EventEmitter<any>();
  updateManagerDetail = new EventEmitter<any>();
  public notifyProjectClientSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyProjectDetailSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public nitifyManagerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyTodosQueriesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyCommentSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyAccountSetupSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyDesignBoardSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyChecklistRepoSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyReleaseNotesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifySlackSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyBuilderPlannerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyMyAccountSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyAllocationsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyPhaseWiseFeedSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyPartnersListSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyRecommendedListSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // public notifyLeftPanelSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public timezones: Array<string>;
  public notifyBlockedReasonSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyMeetingSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyIdeaboardSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notifyCurrentSelection: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public projectMeetingEditComponent: ProjectMeetingEditComponent;
  public notifyIdeaBoard = new EventEmitter<any>();
  public notifyBuildReleaseNote = new EventEmitter<any>();
  // projectTabStatus;
  constructor(public angulartics2Segment: Angulartics2Segment) {
    this.notifyProjectDetailSubject.subscribe((data: any) => {
      this.projectDetail = data;
    });
  }

  public forceRedirect(noques?){
    let specIdParam = localStorage.getItem('force_redirection');
    if(specIdParam != undefined && specIdParam != null && specIdParam != ""){
      return `${!noques ? "?" : "&"}forceid=${localStorage.getItem('force_redirection')}`;
    } 
    else{
      return '';
    }   
  } 


  public trackSegmentEvent(name, prop, isProject?) {
    if (isProject) {
      prop['project_name'] = this.projectDetail ? this.projectDetail.name : '';
      prop['manager_name'] = this.getUserName();
    }
    this.angulartics2Segment.eventTrack(name, prop);
  }


  public notifyProjectClient(data: any) {
    if (data) {
      this.notifyProjectClientSubject.next(data);
    }
  }

  public notifyProjectDetail(data: any) {
    if (data) {
      this.notifyProjectDetailSubject.next(data);
    }
  }

  public notifyComment(data: any) {
    if (data) {
      this.notifyCommentSubject.next(data);
    }
  }

  public notifyManagerDetail(data: any) {
    if (data) {
      this.nitifyManagerSubject.next(data);
    }
  }

  public notifyTodosqueries(data: any, action?) {
    if (data) {
      this.notifyTodosQueriesSubject.next({ data: data, action: action });
    }
  }

  public notifyDesignBoardDetail(data: any) {
    if (data) {
      this.notifyDesignBoardSubject.next(data);
    }
  }

  public notifyChecklistCoderepo(data: any) {
    if (data) {
      this.notifyChecklistRepoSubject.next(data);
    }
  }

  public notifyPhaseWiseFeedDetail(data: any) {
    if (data) {
      this.notifyPhaseWiseFeedSubject.next(data);
    }
  }

  public notifyPartnersListDetail(data: any) {
    if (data) {
      this.notifyPartnersListSubject.next(data);
    }
  }

  public notifyRecommendedList(data: any) {
    if (data) {
      this.notifyRecommendedListSubject.next(data);
    }
  }

  public notifyReleaseNoteDetail(data: any) {
    this.notifyReleaseNotesSubject.next(data);
  }

  public notifyBuilderPlanner(data: any) {
    if (data) {
      this.notifyBuilderPlannerSubject.next(data);
    }
  }

  public notifyAccountSetupDetail(data: any) {
    if (data) {
      this.notifyAccountSetupSubject.next(data);
    }
  }

  public notifyAllocationDetail(data: any) {
    if (data) {
      this.notifyAllocationsSubject.next(data);
    }
  }

  // public showToastMessage(message){
  //   this.toastMessage = message;
  //   this.showToastMsg = true;
  //     setTimeout(()=>{
  //       this.showToastMsg = false;
  //     },2000);
  // }

  public notifySlackDetail(data: any) {
    if (data) {
      this.notifySlackSubject.next(data);
    }
  }

  public notifyBlockedReason(data: any) {
    if (data) {
      this.notifyBlockedReasonSubject.next(data);
    }
  }

  public notifyMyAccountDetail(data: any) {
    if (data) {
      this.notifyMyAccountSubject.next(data);
    }
  }

  public getUserDetail() {

    return window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
  }

  public getUserName() {
    const userDetail: any =  window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
    if (userDetail) {
      return userDetail.first_name + ' ' + userDetail.last_name;
    } else {
      return null;
    }
  }

  public notifyMeeting(data: any) {
    if (data) {
      this.notifyMeetingSubject.next(data);

    }
  }

  public notifyIdeaboardDetail(data: any) {
    if (data) {
      this.notifyIdeaboardSubject.next(data);

    }
  }

  public cancelSubscription(subscription: Subscription) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  setUserProperties(data) {
    const properties = {
      userId: data.id ? data.id : '',
      first_name: data.first_name,
      last_name: data.last_name,
      user_email: data.email,
      country_code: data.country_code,
      user_role: data.user_role ? data.user_role : '',
      user_designation: data.designation ? data.designation : '',
      user_country_name: data.user_country_name ? data.user_country_name : ''
    };
    return properties;
  }

  public setLanguage(projectCurrency) {
    if (projectCurrency != null) {
      switch (projectCurrency) {
        case 'USD':
          this.locale = 'en-US';
          break;
        case 'INR':
          this.locale = 'en-IN';
          break;
        case 'MYR':
          this.locale = 'en-MY';
          break;
        case 'EUR':
          this.locale = 'en-EU';
          break;
        case 'GBP':
          this.locale = 'en-GB';
          break;
        case 'AED':
          this.locale = 'en-AE';
          break;
      }
    }
  }

  public getLocaledPrice(price) {
    if (price != null) {
      price = String(price);
    }
    if (price != null) {
      if (price.indexOf(',') !== -1) {
        price = price.split(',').join('');
      }
      let priceSliced = [];
      let decimalPart = '00';
      if (price.indexOf('.') !== -1) {
        priceSliced = price.split('.');
        price = priceSliced[0];
        decimalPart = (priceSliced[1].toString().length === 1) ? (parseFloat(priceSliced[1] + '0')) : priceSliced[1].slice(0, 2);
      }
      const formatter = new Intl.NumberFormat(this.locale);
      let formatedPrice = formatter.format(price);
      if (formatedPrice.indexOf('.') === -1) {
        formatedPrice = formatedPrice + Constants.PARAM_CONSTANTS.DECIMAL + decimalPart;
      }
      return formatedPrice;
    } else {
      return '0.00';
    }
  }

  public calculateTaxOnAmount(amount, tax) {
    return parseFloat(((amount ? (amount.toString().indexOf('.') > -1) : '')) ? (amount + ((amount * tax) / 100).toFixed(2)) : amount);
  }

  // public notifyLeftPanel(data: any) {
  //     this.notifyLeftPanelSubject.next(data);

  // }

  getFormatedPhone(phone: string) {
    if(phone.charAt(0)==='-'){
      return phone.substring(1); 
    } else if ((phone.charAt(0) !== '-' && phone.charAt(0) === '+' && phone.length >= 10) || (phone.length === 10)) {
      return phone.trim();
    } else if(phone.length >= 10 && phone.charAt(0) !== '+' ) {
      return '+' + phone.trim();
    } else {
      return ''
    }
    // return phone ? phone.indexOf("-") !== -1 ? `+${phone.trim()}` : phone : phone;
  }

  public humanize(str) {
    var frags = str[0].split('_');
    for (let i = 0; i < frags.length; i++) {
      let index = i;
      frags[index] = frags[index].charAt(0).toUpperCase() + frags[index].slice(1);
    }
    return frags.join(' ');
  }

  getName(fname, lname):string{
    let val = ''
    if(!fname && !lname){
      val = '';
    }else{
      if(fname && lname ){
        val = fname[0] + lname[0];
      }else{
        if(fname){
          val = fname[0];
        }else if(lname){
          val = lname[0];
        }
  
      }
      if(val == undefined){
        val = '';
      }
    }
   
    return val;
  }
}
