import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-manager-detail',
  templateUrl: './manager-detail.component.html',
  styleUrls: ['./manager-detail.component.scss']
})
export class ManagerDetailComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
