// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  API_BASE_URL: 'https://api-staging2-pmdashboard.builder.ai/api/v1/pm/',
  API_BASE_URL_FOR_ACCOUNTS: ' https://api-staging2-schedular.builder.ai/',
  apptoken: 'iCxBWGyfgL89NPCwkc-i',
  API_BASE_URL_PAYMENT: 'http://staging-payments.engineer.ai/',
  PAYMENT_TOKEN: 'GT2CLMJrWD5Iy/K1pi8n6XZn8m1HUXy5fBrQbSIomzv81tYODhQQQDN117aOXJBtMVv9bxN0bLRSvJCoX8/+Ww==',
  ZOOMCLIENTID : 'g01JCPqZSIKB2Ne7ru0Sww',
  GOOGLE_CLIENT_ID:'930963822265-4r70gvcgcbm9fp3oid19dd8a9t0ga8e6.apps.googleusercontent.com',
  GOOGLE_CLIENT_SECRET_KEY:'8Fzwv0B1RJZxJ7BKKSWwosEk',
  GITLABURL : 'https://staging.gitlab.builder.ai/clientprojects',
  SCHEDULER_URL : 'https://staging2-scheduler.builder.ai/?token=tpdpdxTvmdfGFyDpWTaK',
  ENABLE_SENTRY:true,
  name:'staging2'
};
