import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import { UserModel } from '../models/user-model/user.model';
import { UrlParameterModel } from '../models/url-parameters-model/url-parameter.model';
import { environment } from '../../../../environments/environment';
import { ProjectStatusService } from '../../../core/services/project-status.service';
import { PartnerdataModel } from '../models/partnerData/partnerdata.model';
import { ProjectModel } from '../models/project-model/project.model';
import { CommonService } from './common.service';

@Injectable()
export class DataService {
  public user: UserModel;
  public authToken: string;
  public urlParameters: UrlParameterModel;
  public partnerData: PartnerdataModel;
  public projectData;
  public releaseStatusList;

  constructor(public projectStatusService: ProjectStatusService, public commonService: CommonService) {
    this.user = new UserModel();
    this.partnerData = new PartnerdataModel();
    this.getPartnerData();

  }

  public forceRedirect(noques?){
    let specIdParam = localStorage.getItem('force_redirection');
    if(specIdParam != undefined && specIdParam != null && specIdParam != ""){
      return `${!noques ? "?" : "&"}forceid=${localStorage.getItem('force_redirection')}`;
    } 
    else{
      return '';
    }   
  }

  public getServerUrl() {
    return environment.API_BASE_URL;
  }
  

  public getPaymentUrl() {
    return environment.API_BASE_URL_PAYMENT;
  }

  public getPaymentToken() {
    return environment.PAYMENT_TOKEN;
  }

  public getServerUrlForPartner() {
    return this.partnerData.vendor_backend_domain + "/api/pm/";
  }

  public getServerUrlForAccounts() {
    return environment.API_BASE_URL_FOR_ACCOUNTS;
  }

  public getAppTokenForAccounts() {
    return environment.apptoken;
  }

  public storeUserObject() {
    localStorage.setItem('user', JSON.stringify(this.user));
    localStorage.setItem('authToken', this.authToken);
  }

  public storeAutoComplete(remeberMe: string) {
    localStorage.setItem('auto', remeberMe);
  }

  public getAutoComplete() {
    return localStorage.getItem('auto') ? JSON.parse(localStorage.getItem('auto')) : null;
  }

  public getRequestOptionArgs(contentType?: string, isFromUrl?: boolean, body?: any) {
    const httpOptions = {  headers:  new HttpHeaders() , body: body};
    if (contentType) {
      httpOptions.headers = httpOptions.headers.set('Content-Type', contentType);
    }
    const user = this.getUserData();
    if (user) {
      const token = this.getAuthToken();
      if (token) {
        httpOptions.headers = httpOptions.headers.set('auth-token', token);
      }
    }

    const partnerData: any = JSON.parse(localStorage.getItem('partnerData'));
    const partnerCode = partnerData ? partnerData.code ? partnerData.code : 'e-ai' : 'e-ai';
    httpOptions.headers = httpOptions.headers.set('partner-code', partnerCode);
    return httpOptions;
  }

  public underscore(key) {
    return key.replace(/([A-Z])/g, ($1) => {
      return '_' + $1.toLowerCase();
    });
  }

  public setIncomingUrlParameters(urlParameters: UrlParameterModel) {
    if (urlParameters) {
      this.urlParameters = urlParameters;
    }
  }

  public logout() {
    this.projectStatusService.projectTabStatus = '';
    this.user = null;
    this.authToken = null;
    localStorage.setItem('user', "");
    localStorage.setItem('authToken', "");
    this.commonService.newHeadertrue = true;
  }

  public getUserData() {
    return this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  }

  public getAuthToken() {
    return this.authToken = localStorage.getItem('authToken') ? localStorage.getItem('authToken') : null;
  }

  public getPartnerData() {
    return this.partnerData = localStorage.getItem('partnerData') ? JSON.parse(localStorage.getItem('partnerData')) : null;
  }

  public platFormDetailsIcon() {
    return this.projectData = localStorage.getItem('platformDetails') ? JSON.parse(localStorage.getItem('platformDetails')) : null;
  }

  public releaseStatus() {
    return this.releaseStatusList = localStorage.getItem('release_status') ? JSON.parse(localStorage.getItem('release_status')) : null;
  }

  public getZoomClientId(){
    return environment.ZOOMCLIENTID;
  }

  public getGoogleClientId(){
    return environment.GOOGLE_CLIENT_ID;
  }
  public getGoogleSecretkey(){
    return environment.GOOGLE_CLIENT_SECRET_KEY;
  }
  public getGitLabUrl() {
    return environment.GITLABURL;
  }

}
