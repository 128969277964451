export class UserModel {
  id: string;
  role?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  department?: string;
  office?: string;
  designation?: string;
  theme_code?: string;
  country_code?: string;
  profile_pic?: any;
  initials?: string;
  projects_count?: number;
  projects?: any;
  primary_projects_count?: number;
  assigned_projects_count?: number;
  pipeline_projects_count?: number;
  ongoing_projects_count?: number;
  shipped_projects_count?: number;
  blocked_projects_count?: number;
  stopped_projects_count?: number;
  active_status?: string;
  meeting_status?: string;
  all_meetings_status?: any;
  zoom_authorization_code?:string;
  skip_spec_overlap?:boolean;
  spec_reason?:string;
  google_cal_access_token?:string;
  available_date: any;
  all_designations?:string[];



  partner?: {name?: string, code?: string, variance_percentage?: any, partners_type?: string};
  
  // name(): string {
  //   return this.first_name + ' ' + this.last_name;
  // }
}
