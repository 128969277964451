import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequests } from '../../../shared/shared-module/services/apiRequests.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { CommonService } from '../../../shared/shared-module/services/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  @ViewChild('password', { static: true }) public password;
  @ViewChild('cpassword', { static: true }) public confirmPassword;
  public data = { reset_password_token: '', password: '', password_confirmation: '' };
  public hideShow = { passShow: false, confirmShow: false };
  public CommonErrorMsg = '';

  constructor(public commonService: CommonService, public dataService: DataService, public router: Router,
    public apiRequest: ApiRequests, public activatedRoute: ActivatedRoute) {
  }

  public ngOnInit() {
   this.data.reset_password_token = window.location.href.split('?')[1].split('=')[1];
   this.commonService.newHeadertrue = true;
   if (this.dataService.getUserData()) {
    this.commonService.newHeadertrue = false;
    this.router.navigate(['/projects']);
    return;
  }
   if (this.data.reset_password_token) {
     localStorage.clear();
     return;
   }
  }

  checkPass(): boolean {
    if (this.password && this.confirmPassword && this.password.touched && this.confirmPassword.touched && this.password.value.length >= 8 && this.confirmPassword.value.length >= 8) {
      if ((this.password.value === this.confirmPassword.value) && this.password.value !== '' && this.confirmPassword.value !== '') {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  public checkpassValidity(): boolean {
    return ((!this.data.reset_password_token) || (!this.data.password) || (!this.data.password_confirmation)
      || this.ispasswordError(true) || this.ispasswordError());
  }

  public resetClick() {
    this.apiRequest.login_signup(this.data, 'reset').subscribe((data: any) => {
      this.commonService.resetSucess = true;
      this.commonService.newHeadertrue = true;
      this.router.navigate(['login']);
    }, error => {
      this.CommonErrorMsg = error.error;
      if (this.CommonErrorMsg !== '') {
        this.CommonErrorMsg = "This link has expired";
      }
    });
  }

  public ispasswordError(isConfirmpass?: boolean): boolean {
    if (isConfirmpass) {
      return this.confirmPassword && (this.confirmPassword.touched || this.confirmPassword.dirty) && (this.confirmPassword.errors || this.checkSpaceCpassword());
    }
    return this.password && (this.password.touched || this.password.dirty) && (this.password.errors);
  }

  public passwordLengthCheck(isConfirmpass?: boolean): boolean {
    if (isConfirmpass) {
      return this.confirmPassword && this.confirmPassword.errors.minlength && !this.ispasswordError(true);
    }
    return this.password && this.password.errors.minlength && !this.ispasswordError();
  }

  public passwordRequiredCheck(isConfirmpass?: boolean): boolean {
    if (isConfirmpass) {
      return this.confirmPassword && this.confirmPassword.errors && this.confirmPassword.errors.required;
    }
    return this.password && this.password.errors && this.password.errors.required;
  }

  public checkSpacePassword() {
    if (this.password && this.password.touched && this.password.value.indexOf(' ') >= 0) {
      return true;
    }
    return false;
  }

  public checkSpaceCpassword() {
    if (this.confirmPassword && this.confirmPassword.touched && this.confirmPassword.value.indexOf(' ') >= 0) {
      return true;
    }
    return false;
  }


}
