import { Component, Renderer2, HostListener, ApplicationRef, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../shared/shared-module/services/data.service';
import { Router } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { CommonService } from '../../shared/shared-module/services/common.service';
import { ToastMessageService } from '../services/toast.message.service';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  // showPanel = true;

  public showErrorScreen = false;
  public showErrorScreen_401 = true;
  public showErrorScreen_404 = false;
  public showErrorScreen_500 = false;
  public isLoader: boolean = false;
  private loadingSubscription: Subscription;

  ngOnInit(){
    
  }

  ngAfterViewInit(){
    this.loadingSubscription = this.loaderService.showPageloader.subscribe((value) => {
      this.isLoader = value;
      this.cd.detectChanges();
    });
  }

  constructor(public toastMessageService: ToastMessageService,
    public commonService: CommonService,
    public ref: ApplicationRef,
    public router: Router,
    public loaderService: LoaderService,
    public dataService: DataService,
    private renderer: Renderer2,
    angulartics2Segment: Angulartics2Segment,
    private cd: ChangeDetectorRef) {
    this.renderer.addClass(document.body, 'green');
    // this.loadingSubscription = this.loaderService.showPageloader.subscribe((value) => {
    //   this.isLoader = value;
    //   this.cd.detectChanges();
    // });
   
  }

  ngOnDestory(){
    this.loadingSubscription.unsubscribe();
  }

  closeSaveTost() {
    this.toastMessageService.showToastMsg = false;
  }
}
