<section class="middle-sec common-redesign"  *ngIf = "!dataService.getUserData()">
  <div class="reset-success-msg" *ngIf="getresetVar()">
    <img src="assets/images/tick-circle.png" alt="">
    <h3>Your password has been reset successfully</h3>
    <a class="ml-auto" (click)="commonService.resetSucess=!commonService.resetSucess"><i class="fa fa-times"
                                                                                         aria-hidden="true"></i></a>
  </div>
  <div class="pm-dashboard-modal">
    <h2>Log In</h2>
    <form class="common-form" name="loginForm" #loginForm = "ngForm">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="email_add">Email address</label>
            <div class="common-input-box sa-email-box">  
              <input id="email_add" tabindex="1" type="email" name="email" placeholder="You@example.com" class="common-input" required [autocomplete]="checkAutoComplete()"
                    maxlength="100" (keydown)="removeErrMsg()"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" #email="ngModel"
                    [(ngModel)]="login_data.email"
              />
            </div>
            <!--<label>
              <span>Email
                  <span>*</span>
              </span>
            </label>-->
            <div class="invalid-feedback" *ngIf="(email.touched ) && email.invalid && email.errors.required">Please enter registered email</div>
            <div class="invalid-feedback" *ngIf="(email.touched ) && email.invalid && email.errors.pattern">Please enter valid registered email</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-6">
                <label for="password_bx">Password</label>
              </div>
              <div class="col-sm-6 text-right">
                <a  tabindex="4" class="forget-pass" [routerLink]="['/forgot-password']">Forgot ? </a>
              </div>
            </div>
            <div class="common-input-box sa-password-box">
              <input id="password_bx" tabindex="2" class="common-input" placeholder="8+ characters" required [autocomplete]="checkAutoComplete()" maxlength="100"
                   [(ngModel)]="login_data.password" minlength = "8" name="password" (keydown)="removeErrMsg()"
                   type="{{loginPass.show?'text':'password'}}" #password="ngModel" required/>
                   <i [ngClass]="{'active': loginPass.show }" class="clear-icon" (click)="showPassword()" *ngIf="login_data.password"></i>
                   <!--<img class="clear-icon" src="assets/images/view_eye.svg" (click)="showPassword()" *ngIf="login_data.password" />-->
            </div>     
            <!--<label>
              <span>Password
                <span>*</span>
              </span>
            </label>-->
            <div *ngIf="(password.touched || password.dirty) && password.errors">
            <!-- <div *ngIf="ispasswordError()"> -->
              <div class="invalid-feedback" *ngIf="password.touched && password.invalid && password?.errors.minlength">Password to be entered between 8-30 characters</div>
              <div class="invalid-feedback" *ngIf="passwordRequiredCheck()">Password does not match with the registered email</div>
            </div>
            <span class="invalid-feedback">{{CommonErrorMsg}}</span>
          </div>
        </div>
      </div>
      <!--<div class="row">
        <div class="col-md-6">
          <div class="form-group custom-checkbox text-left">
            <p>Remember Me</p>
            <label>Remember Me
              <input type="checkbox" (click)="rememberMeClick()">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="col-md-6 text-right">
          <div class="form-group">
            <p><a class="forget-pass" [routerLink]="['/forgot-password']">Forgot Password?</a></p>
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input  tabindex="3" type="submit" class="btn btn-primary comm-login-module-btn" name="submit" value="Login"
                   (click)="loginClick(loginForm)" [disabled]="loginForm.invalid">
          </div>
        </div>
      </div>
    </form>
    <!-- <p>I don't have an account?
      <a [routerLink]="['/signup']">Register</a>
    </p> -->
  </div>
</section>

