<section class="middle-sec common-redesign">
    <div class="pm-dashboard-modal">
      <div *ngIf="!showVerifyPage">
        <h2>Sign Up</h2>
      </div>
      <form class="common-form" #signupform="ngForm" name="signupform" *ngIf="!showVerifyPage">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{'crossInviteSignup': isCrossInvite}">
              <label for="firstname">First Name</label>
              <div class="common-input-box sa-name-box">
                <input id="firstname" type="text" name="firstName" placeholder="Your first name?" class="common-input" required autocomplete="off" maxlength="100"
                  #firstName="ngModel" pattern="[a-zA-Z0-9]+"
                  [(ngModel)]="data.first_name"/>
              </div>       
              <!--<label>
                <span>First Name
                    <span>*</span>
                </span>
              </label>-->
              <div class="invalid-feedback" *ngIf="checkFirstNameValid()"> Only alphanumeric characters are allowed</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{'crossInviteSignup': isCrossInvite}">
              <label for="lastname">Last Name</label>
              <div class="common-input-box sa-name-box">
                <input type="text" id="lastname" placeholder="Your last name?" name="lastName"  class="common-input" required autocomplete="off" maxlength="100"
                  #lastName="ngModel" pattern="[a-zA-Z0-9]+"
                  [(ngModel)]="data.last_name"/>
              </div>       
              <!--<label>
                <span>Last Name
                    <span>*</span>
                </span>
              </label>-->
              <div class="invalid-feedback" *ngIf="checkLastNameValid()"> Only alphanumeric characters are allowed</div>
            </div>
          </div>
        </div>
        <!--<div class="row">
          <div class="col-md-12">
              <div class="form-group">
                  <input type="text" name="name" class="common-input" required autocomplete="off" />
                  <label>
                      <span>Initials
                          <span>*</span>
                      </span>
                  </label>
                  <div class="invalid-feedback">This initial is already taken!</div>
              </div>
          </div>
      </div>-->
        <!--<div class="row suggestions-row">
            <div class="col-md-12">
                <div class="form-group d-flex align-items-center">
                    <label>Suggestions:</label>
                    <ul class="suggestions-list">
                        <li>PY</li>
                        <li>MK</li>
                        <li>PA</li>
                        <li>PR</li>
                        <li>PM</li>
                    </ul>
                </div>
            </div>
        </div>-->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" [ngClass]="{'crossInviteSignup': isCrossInvite}">
              <label for="email_bx">Email address</label>
              <div class="common-input-box sa-email-box">
                <input id="email_bx" placeholder="You@example.com" type="email" name="email" #email="ngModel" [(ngModel)]="data.email" maxlength="100"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="common-input" required
                autocomplete="off"/>
              </div>
              <!--<label>
                <span>Email
                    <span>*</span>
                </span>
              </label>-->
              <div class="invalid-feedback" *ngIf="checkIfEmailValid()">Please enter valid email</div>
              <span class="invalid-feedback">{{CommonErrorMsg}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="password_bx">Password</label>
              <div class="common-input-box sa-password-box">
                <input name="password" id="password_bx" placeholder="8+ characters" class="common-input" maxlength="30" minlength="8" #password="ngModel"
                [(ngModel)]="data.password" type="{{pass.show?'text':'password'}}" required autocomplete="off"/>
                <i [ngClass]="{'active': pass.show }" class="clear-icon" aria-hidden="true" (click)="showPassword()" *ngIf="data.password"></i>
              </div>     
                <!--<label>
                  <span>Password
                      <span>*</span>
                  </span>
                </label>-->
              <div *ngIf="ispasswordError()">
                <div class="invalid-feedback" *ngIf="password.touched && password.errors.minlength && !checkSpacePassword()">Password to be entered between 8-30 characters</div>
                <div class="invalid-feedback" *ngIf="passwordRequiredCheck()">Password is required</div>
                <div class="invalid-feedback" *ngIf="checkSpacePassword()">Password doesn't consist spaces</div>
              </div>
              
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group mb0">
              <label for="phone_num">
                Phone
              </label>
            </div>
          </div>
          <div class="col-md-3 pr0">
            <div class="form-group phoneNumber mb0">
              <div class="common-input-box">
                <div class="ext-wrp">
                  <international-phone-number id="international_number" [(ngModel)]="data.country_code" [maxlength]="20" [ngModelOptions]="{standalone: true}"
                  [required]="true" #countryCode="ngModel">
                  </international-phone-number>
                    <div class="input-val">
                      {{data.country_code ? data.country_code : '+91'}}
                    </div>
                </div>
              </div>  
              <!-- <div class="invalid-feedback" *ngIf="countryCode.touched && countryCode.invalid">Please select country code</div> -->
            </div>
          </div>
          <div class="col-md-9 pl0">
            <div class="form-group mb0">
              <div class="common-input-box sa-phone-box">
                  <input type="tel" id="phone_num" name="phone" #phone="ngModel" [(ngModel)]="data.phone"  pattern = "[0-9]+" minlength = "10" maxlength="10" class="common-input"
                     (keypress)="onlyNumber($event)" required autocomplete="off"/>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div class="invalid-feedback" *ngIf="checkIfMobileValid()">Mobile number should be 10 digits. Please enter valid phone number</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group m12">
              <input type="submit" class="btn btn-primary comm-login-module-btn" name="submit" value="Signup"
                     (click)="singupClick(signupform)" [disabled]="checkSignupValidity() || (signupform.invalid) ">
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="!showVerifyPage">
        <p>Already have an account?
          <a [routerLink]="['/login']">Login</a>
        </p>
      </div>
      <div *ngIf="showVerifyPage">
        <h2>Please verify your email</h2>
        <p class="pb-4">Once you verify your email address, you can get started with PM Tool.</p>
        <p>Back to
          <a [routerLink]="['/login']">Login</a>
        </p>
      </div>
    </div>
  </section>