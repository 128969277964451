
import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from "@sentry/browser";

import { environment } from "../../../environments/environment";

Sentry.init({

  dsn: "https://7b27296d5bed44e585e9dc98d7912b3c@sentry.engineer.ai/10",
  environment: environment.name
});
@Injectable()

export class GlobalErrorHandler implements ErrorHandler {
  
  handleError(error: any): void {
    if (environment.ENABLE_SENTRY) {
      const eventId = Sentry.captureException(error.originalError || error);
      //Sentry.showReportDialog({eventId});
    }
    
   const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }else{
      console.error(error)
    }
    
  }
}