import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../shared/shared-module/services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(public dataService: DataService) {

  }

  ngOnInit() {
  }
}

