import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../shared/shared-module/services/data.service';

@Component({
  selector: 'app-under-maintainance',
  templateUrl: './under-maintainance.component.html',
  styleUrls: ['./under-maintainance.component.scss']
})
export class UnderMaintainanceComponent implements OnInit {

  constructor(public router: Router, public dataService: DataService) { }

  ngOnInit() {
    console.log(this.dataService.user)
  }

  homePage() {
    this.router.navigate(['/projects']);
  }

}
