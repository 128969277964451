import {Routes} from '@angular/router';
import {SignupComponent} from './core/login-module/signup/signup.component';
import {LoginComponent} from './core/login-module/login/login.component';
import {ForgotPasswordComponent} from './core/login-module/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './core/login-module/reset-password/reset-password.component';
// import { MyAccountComponent } from './core/my-account/my-account.component';
import {CodeRepoComponent} from './core/app-module/code-repo/code-repo.component';
import {BuildReleaseComponent} from './core/app-module/build-release/build-release.component';
import {ManagerComponent} from './core/app-module/manager/manager.component';
import {ManagerDetailComponent} from './core/app-module/manager-detail/manager-detail.component';
// import { TodosQueriesComponent } from './projects/todos-queries/todos-queries.component';
import {AccountInfoComponent} from './core/accounts-module/account-info.component';
import {AuthGuard} from "./auth-guard.service";
import { PageNotFoundComponent } from './core/app-module/page-not-found/page-not-found.component';
import { UnauthorizedAccessComponent } from './core/app-module/unauthorized-access/unauthorized-access.component';
import { UnderMaintainanceComponent } from './core/app-module/under-maintainance/under-maintainance.component'


export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: 'signup',
    component: SignupComponent
  }, {
    path: 'dashboard',
    loadChildren: () => import('app/core/dashboard-module/dashboard.module').then(m => m.DashboardModule),
  },
  // {
  //   path: "projects/:id",
  //   loadChildren: "app/core/project-module/project.module#ProjectModule"
  // },
  {
    path: 'projects',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/core/project-module/project.module').then(m => m.ProjectModule)
  },
  {
    path: 'spec-calls',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/core/spec-calls/spec-calls.module').then(m => m.SpecCallsModule)
  },  
  {
    path: 'my-account',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/core/my-account-module/my-account.module').then(m => m.MyaccountModule)
  },
  {
    path: 'managers',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/core/manager-module/manager.module').then(m => m.ManagerModule)
  },
  {
    path: 'partners',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/core/partner-module/partner.module').then(m => m.PartnerModule)
  },
  {
    path: 'accounts',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/core/accounts-module/account-info.module').then(m => m.AccountInfoModule)
  },
  {
    path: 'repository',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/core/repository-module/repository-module.module').then(m => m.RepositoryModuleModule)
  },
  {
    path: 'login',
    component: LoginComponent
  }, {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  }, {
    path: 'reset-password',
    component: ResetPasswordComponent
  }, {
    path: 'code-repo',
    component: CodeRepoComponent
  }, {
    path: 'build-release',
    component: BuildReleaseComponent
  }, {
    path: 'manager',
    component: ManagerComponent
  }, {
    path: 'manager-detail',
    component: ManagerDetailComponent
  }, {
    path: 'under-maintainance',
    component: UnderMaintainanceComponent
  }, {
    path: 'unauthorized-access',
    component: UnauthorizedAccessComponent
  }, {
    path: 'page-not-found',
    component: PageNotFoundComponent
  }, {
    path: '**',
    redirectTo: 'login'
  }
];
