import { Component, OnInit,Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { ApiRequests } from "../../../shared/shared-module/services/apiRequests.service";
import { LoaderService } from '../../services/loader.service';
import { ProjectStatusService } from '../../services/project-status.service';
import { ModalDirective } from 'ngx-bootstrap';
import { ToastMessageService } from '../../services/toast.message.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public leftPanel;
    public commonService;
    public headerLogoUrl = '';
    public userData;
    public statusShowHide = false;
    public currentThemeIndex = 0;
    // public currentStatus;
    isShowHeaderDropDown = false;
    public reasonType;
    public statusArray = [];
    public selectedIndex = 0;
    public reasonArray = [{ name: 'Meeting ended early', value: 'Meeting ended early' }, { name: 'Customer dropped off', value: 'Customer dropped off' }];
    public themeData = [{
        colorValue: '#4fa399',
        colorText: 'green'
    }, {
        colorValue: '#618dcc',
        colorText: 'blue'
    }, {
        colorValue: '#ad80d1',
        colorText: 'purple'
    }, {
        colorValue: '#8ace8a',
        colorText: 'lightGreen'
    }, {
        colorValue: '#8ea0a8',
        colorText: 'grey'
    }, {
        colorValue: '#5688a1',
        colorText: 'softBlue'
    }];
    @ViewChild('SpecStatusModal', { static: true }) SpecStatusModal: ModalDirective;
    constructor(public dataService: DataService,
        public toastMessageService: ToastMessageService,
        public router: Router,
        commonService: CommonService,
        public renderer: Renderer2,
        public loaderService: LoaderService,
        public apiRequests: ApiRequests, public projectStatusService: ProjectStatusService) {
        this.commonService = commonService;
    }
    public showSpecStatusModal() {
        this.selectedIndex = 0
        this.reasonType = '';
        this.SpecStatusModal.show();
    }

    public closeSpecStatusModal() {
        this.reasonType = '';
        this.commonService.currentStatus = this.dataService.user.meeting_status
        this.SpecStatusModal.hide();
    }
    ngOnInit() {
        if (this.dataService.user.id) {
            this.apiRequests.getUpdatedUserData().subscribe(() => {
                localStorage.setItem('user', JSON.stringify(this.dataService.user));
                this.commonService.currentStatus = this.dataService.user.meeting_status
            });
        }
        this.commonService.notifyMyAccountSubject.subscribe((next) => {
            this.userData = this.dataService.getUserData();
            let statusArray = this.dataService.user ? this.dataService.user.all_meetings_status : '';
            this.statusArray = statusArray ? Object.keys(statusArray) : [];
            this.commonService.currentStatus = this.dataService.user ? this.dataService.user.meeting_status : 'Not Available';
            if (this.userData) {
                this.themeData.forEach((color, index) => {
                    if (color.colorValue === this.dataService.user.theme_code) {
                        this.removeAllClassesFromBody();
                        this.currentThemeIndex = index;
                        this.renderer.addClass(document.body, color.colorText);
                    }
                });
            }
        });
    }

    toggleHeaderDropDown(event: Event) {
        event.stopPropagation();
        this.isShowHeaderDropDown = this.isShowHeaderDropDown ? false : true;
        document.querySelector('body').addEventListener('click', () => {
            this.isShowHeaderDropDown = false;
            document.querySelector('body').removeEventListener('click', () => {
            });
        });
    }

    logout() {
        this.apiRequests.logoutSession().subscribe((response => {
            if (response.message === 'success') {
                this.commonService.newHeadertrue = true;
                this.projectStatusService.projectTabStatus = '';
                this.dataService.user = null;
                this.dataService.authToken = null;
                localStorage.setItem('user', "");
                localStorage.setItem('authToken', "");
                localStorage.removeItem('projectAllocationTab');
                this.router.navigate(['/login']);
                this.removeAllClassesFromBody();
            }
        }));
    }

    goToProjects() {
        if (this.router.url === '/projects') {
            return;
        }
        if (this.checkUserLogged()) {
            this.loaderService.show();
            this.router.navigate(['/projects']);
        } else {
            this.router.navigate(['/login']);
        }
        
    }

    checkUserLogged() {
        return this.dataService.user;
    }

    removeAllClassesFromBody(): void {
        this.renderer.removeClass(document.body, 'green');
        this.renderer.removeClass(document.body, 'blue');
        this.renderer.removeClass(document.body, 'purple');
        this.renderer.removeClass(document.body, 'lightGreen');
        this.renderer.removeClass(document.body, 'grey');
        this.renderer.removeClass(document.body, 'softBlue');
    }

    statusShow(type, event) {
        // this.apiRequests.getUpdatedUserData().subscribe(() => { });
        this.viewStatus(type, event);
        // this.statusShowHide = type ? false : true;
    }

    viewStatus(type, event: Event) {
        event.stopPropagation();
        this.statusShowHide = type ? false : true;
        document.querySelector('body').addEventListener('click', () => {
            this.statusShowHide = false;
            document.querySelector('body').removeEventListener('click', () => {
            });
        });
    }

    statusUpdate(type) {
        this.loaderService.show();
        this.commonService.currentStatus = type;
        this.statusShowHide = false;
        let statusObj = {
            'meeting_status': this.commonService.currentStatus
        }
        this.apiRequests.updateUserDetails(statusObj).subscribe((data: any) => {
            if (data.message === 'success') {
                this.dataService.user.meeting_status = data.data.user.meeting_status;
                localStorage.setItem('user', JSON.stringify(this.dataService.user));
                this.loaderService.hide();
            }
        }, (error) => {
            if (error.error.skip_spec_overlap) {
                this.showSpecStatusModal();
                this.loaderService.hide();
            } else {
                this.toastMessageService.showToastMessage(error.error);
                this.commonService.currentStatus = this.dataService.user.meeting_status
                this.SpecStatusModal.hide();
                this.loaderService.hide();
              }
        });

    }

    temp(type, index) {
        this.selectedIndex = index;
        if (this.selectedIndex === 0) {
            this.reasonType = type;
        } else if (this.selectedIndex === 1) {
            this.reasonType = type;
        } else {
            this.reasonType = '';
        }
    }

    confirmStatusChange() {
        this.loaderService.show();
        if (this.reasonType === '') {
            this.reasonType = 'Meeting ended early';
        }
        this.statusShowHide = false;
        let data= { user: { meeting_status: this.commonService.currentStatus, skip_spec_overlap: true, spec_reason:  this.reasonType} };
        this.apiRequests.updateUserDetails(data).subscribe((data: any) => {
            if (data.message === 'success') {
                this.dataService.user.skip_spec_overlap = data.data.user.skip_spec_overlap;
                this.dataService.user.meeting_status = data.data.user.meeting_status;
                this.dataService.user.spec_reason = data.data.user.spec_reason;
                localStorage.setItem('user', JSON.stringify(this.dataService.user));
                this.SpecStatusModal.hide();
                this.loaderService.hide();
            }
        });
    }


}
